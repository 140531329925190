import {BigEmailIcon} from "../assets/BigEmailIcon";
import {Logo} from "../assets/Logo";
import {BigInstaIcon} from "../assets/BigInstaIcon";
import {SmallEmailIcon} from "../assets/SmallEmailIcon";
import {SmallInstaIcon} from "../assets/SmallInstaIcon";
import QR from "../assets/QR.svg";
import Screen4 from "../assets/Screen4.webp";
import LastCoin1 from "../assets/lastcoin1.webp";
import LastCoin2 from "../assets/lastcoin2.webp";
import {Button} from "@nextui-org/react";
import {AppStoreIcon} from "../assets/AppStoreIcon";
import {GooglePlayIcon} from "../assets/GooglePlayIcon";

const Footer = () => {

    const lang = localStorage.getItem("lang");

    const l = {
        "ru": {
            "text1": {
                "label": "Станьте нашим",
                "color": "white"
            },
            "text2": {
                "label": "партнером",
                "color": "#BDFF00"
            },
            "text3": {
                "label": "Оставьте заявку, наши специалисты с вами свяжутся",
                "color": "white"
            },
            "text4": {
                "label": "Оставить заявку",
                "color": "black"
            },
            "text5": {
                "label": "Скачать sorpa pay",
                "color": "white"
            },
            "text6": {
                "label": "Наша миссия",
                "color": "black"
            },
            "text7": {
                "label": "Мы стремимся объединить предпринимателей и\n" +
                    "клиентов в экосистеме взаимной выгоды, где\n" +
                    "каждая транзакция приносит радость и пользу",
                "color": "black"
            },
            "text8": {
                "label": "Sorpa - всегда с добавкой!",
                "color": "black"
            },
            "text9": {
                "label": "Правила платежной организаций",
                "color": "black"
            },
            "text10": {
                "label": "Правила пользования",
                "color": "black"
            },
            "text11": {
                "label": "Политика конфиденциальности",
                "color": "black"
            },
            "text12": {
                "label": "(c) Разработано Частная Компания \"Clover Ltd\". \n" +
                    "Все права защищены ТОО “Сорпа КЗ” 2024.",
                "color": "black"
            },
        },
        "kk": {
            "text1": {
                "label": "Біздің",
                "color": "white"
            },
            "text2": {
                "label": "Серіктестеріміз",
                "color": "#BDFF00"
            },
            "text22": {
                "label": "болыңыз",
                "color": "white"
            },
            "text3": {
                "label": "Өтініш қалдырыңыз, біздің мамандарымыз сізбен хабарласады",
                "color": "white"
            },
            "text4": {
                "label": "Өтініш қалдыру",
                "color": "black"
            },
            "text5": {
                "label": "sorpa pay жүктеп алу",
                "color": "white"
            },
            "text6": {
                "label": "Біздің миссиямыз",
                "color": "black"
            },
            "text7": {
                "label": "Біз әрбір транзакция қуаныш пен пайда әкелетін\n" +
                    " өзара тиімді экожүйеде кәсіпкерлер мен клиенттерді\n" +
                    " біріктіруге ұмтыламыз",
                "color": "black"
            },
            "text8": {
                "label": "Sorpa - Әрқашан қосымшамен!",
                "color": "black"
            },
            "text9": {
                "label": "Төлем ұйымының ережелері",
                "color": "black"
            },
            "text10": {
                "label": "Пайдалану ережелері",
                "color": "black"
            },
            "text11": {
                "label": "Құпиялылық саясаты",
                "color": "black"
            },
            "text12": {
                "label": "(c) Clover Ltd. 2024.Барлық құқықтар қорғалған",
                "color": "black"
            },
        }
    }

    return(
        <footer id={'contact'} style={{background: "#DEE2ED", paddingBottom:60}}>
            <div className={"container"}>
                <div className={"w-full relative z-40 mt-[40px] flex justify-between gap-[24px] overflow-hidden bg-[#222222] rounded-[24px] min-h-[570px] md:min-h-fit"}>
                    <div className={"w-full mb-[40px] p-[24px] text-white flex flex-col justify-between"}>
                        <div className={"flex flex-col gap-[16px] relative z-50 w-full"}>
                            <h1 className={"text-[40px] text-white font-[600] leading-[56px]"}>{l[lang]?.text1?.label}  {lang === "ru" && <br/>} <span className={"text-[#BDFF00]"}> {l[lang]?.text2?.label}</span> {lang === "kk" && l[lang]?.text22?.label}</h1>
                            <p>{l[lang]?.text3?.label}</p>
                            <div className={"flex w-full justify-between gap-[16px] flex-col md:flex-row"}>
                                <a href={"https://business.sorpa.com/signup"}><Button className={"text-black bg-[#BDFF00] font-[600] w-full md:max-w-[270px]"}>{l[lang]?.text4?.label}</Button></a>
                            </div>
                        </div>
                        <div className={"flex flex-col gap-[16px] mt-[20px]"}>
                            <p className={"w-full"}>{l[lang]?.text5?.label}</p>
                            <div className={"flex justify-start w-full gap-[16px] relative z-30"}>
                                {/*<AppStoreIcon/>*/}
                                <a href={"https://play.google.com/store/apps/details?id=com.bekinston.sorpapayv1"}><GooglePlayIcon/></a>
                            </div>
                        </div>
                        <div className={"relative w-full flex md:hidden"}>
                            <img src={LastCoin1} className={"max-w-[150px] absolute bottom-[70px] left-[160px] z-20"}/>
                            <img src={LastCoin2} className={"max-w-[130px] absolute bottom-[-70px] left-0 z-20"}/>
                        </div>
                    </div>
                    <div className={"relative w-full hidden md:flex"}>
                        <img src={Screen4} className={"max-w-[295px] absolute bottom-0 right-[320px] z-20"}/>
                        <img src={QR} className={"max-w-[265px] absolute bottom-[-20px] right-[30px] z-20"}/>
                    </div>
                </div>
            </div>
            <div className={'container'}>
                <div className={"footer-main-wrapper"} style={{display:"flex", gap:24, flexDirection:"column"}}>
                    <h2 style={{maxWidth:400, textAlign:"center", fontSize:32, fontWeight:600}}>{l[lang]?.text6?.label}</h2>
                    <p style={{maxWidth:400, textAlign:"center"}}>{l[lang]?.text7?.label}</p>
                </div>
                <div className={"footer-info-block"} style={{width:"100%", background:'#FFF', borderRadius:30, padding: 40}}>
                    <a href={'mailto:info@sorpa.com'} style={{display:"flex", alignItems:"center", gap:16, width:"100%"}}><BigEmailIcon/>info@sorpa.com</a>
                    <div className={'header-wrapper'} style={{width:6, background:"black"}}/>
                    <a href={'https://www.instagram.com/sorpa.app/'} target={"_blank"} className={"insta-wrapper"} style={{display:"flex", alignItems:"center", gap:16, width:"100%"}}><BigInstaIcon/>@sorpa.app</a>
                </div>
                <div className={"footer-end"}>
                    <div style={{display:"flex", flexDirection:"column", gap:30, width:"100%"}}>
                        <Logo/>
                        <p>{l[lang]?.text8?.label}</p>
                        <div style={{display:"flex", gap:24}}>
                            <a href={"https://apps.apple.com/kz/app/sorpa/id6480465302"}><AppStoreIcon/></a>
                            <a href={"https://play.google.com/store/apps/details?id=com.bekinston.Sorpa&hl=en"}><GooglePlayIcon/></a>
                        </div>
                    </div>
                    <div style={{display:"flex", flexDirection:"column", gap:30, width:"100%"}}>
                        <p className={"footer-links"} style={{fontSize:12}} onClick={() => {
                            setTimeout(()=>{window.scrollTo(0,0)}, [100])
                        }}><a href={'/conditions'}>{l[lang]?.text9?.label}</a> | <a href={'/rules'}>{l[lang]?.text10?.label}</a> <span className={"hidden md:inline"}>|</span> <a href={'/policy'} className={"w-full md:w-auto"}><br className={"flex md:hidden"}/>{l[lang]?.text11?.label}</a></p>
                        <div className={"footer-links"} style={{display:"flex", gap:30}}>
                            <a href={'mailto:info@sorpa.com'}><SmallEmailIcon/></a>
                            <a target={"_blank"} href={'https://www.instagram.com/sorpa.app/'}><SmallInstaIcon/></a>
                        </div>
                        <div style={{display:"flex", gap:24}}>
                            <p className={"footer-links"} style={{fontSize:12}}>{l[lang]?.text12?.label}</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
