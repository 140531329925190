export const Conditions = () => {
    const lang = localStorage.getItem("lang");

    if (lang === "kk") {
        return (
            <div className={"container"} style={{gap: 12}} dangerouslySetInnerHTML={{
                __html: '<font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Құпиялылық\n' +
                    'саясаты және төлем шарттары</b></span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>I.\n' +
                    'Төлемдер.</b></span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>1.\n' +
                    'Банктік картамен онлайн төлем</b></span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Sorpa\n' +
                    'pay қызметі сіздің клиентіңіздің Sorpa\n' +
                    'қосымшасындағы балансынан төлем қабылдай\n' +
                    'алады. Сізге шот-фактура шығарып, QR-кодты\n' +
                    'жасап, оны клиентіңізге Sorpa қосымшасы\n' +
                    'арқылы төлеуге мүмкіндік беру қажет.</span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>2.\n' +
                    'Қызметті сатып алу ережелері</b></span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Sorpa\n' +
                    'pay қызметінде таңдалған тарифті төлеу\n' +
                    'арқылы сіз Sorpa pay мобильді қосымшасында\n' +
                    'орналастырылған көпшілікке арналған\n' +
                    'оферта шартымен келісетініңізді\n' +
                    'растайсыз.</span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>3.\n' +
                    'Қаражаттарды қайтару</b></span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Қаражаттарды\n' +
                    'банктік картаға қайтару үшін, Сізге\n' +
                    '“Қаражаттарды қайтару туралы өтінішті”\n' +
                    'толтыру қажет. Бұл өтініш компаниядан\n' +
                    'сұраныс бойынша электрондық пошта\n' +
                    'арқылы жіберіледі. Өтінішпен бірге жеке\n' +
                    'басын куәландыратын құжаттың көшірмесін\n' +
                    'info@sorpa.com мекенжайына жіберу қажет.\n' +
                    'Қаражаттарды қайтару өтініш компаниямен\n' +
                    'алынғаннан кейін 10 жұмыс күні ішінде\n' +
                    'жүзеге асырылады, егер қызмет көрсетуде\n' +
                    'негізделген шағымдар болса. Қателіктермен\n' +
                    'өткізілген операциялар бойынша\n' +
                    'қаражаттарды қайтару үшін, жазбаша\n' +
                    'өтінішпен, жеке басын куәландыратын\n' +
                    'құжаттың көшірмелерімен және қате түрде\n' +
                    'алынғанын растайтын түбіртек/чектермен\n' +
                    'бірге info@sorpa.com мекенжайына жіберу қажет.\n' +
                    'Қайтару сомасы қызмет көрсету шығындарын\n' +
                    'шегергендегі сатып алу сомасына тең\n' +
                    'болады. Өтінішті қарау және қаражаттарды\n' +
                    'қайтару мерзімі Компания өтінішті алған\n' +
                    'сәттен бастап жұмыс күндерімен есептеледі,\n' +
                    'мереке/демалыс күндері есепке алынбайды.</span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>4.\n' +
                    'Төлем жасаудан бас тарту себептері:</b></span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e">•\n' +
                    '<font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Қосымша\n' +
                    'интернет арқылы төлем жасау үшін\n' +
                    'арналмаған, мұны анықтау үшін сіздің\n' +
                    'эмитент банкіңізге хабарласыңыз;</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e">•\n' +
                    '<font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Баланста\n' +
                    'төлем жасауға жеткілікті қаражат жоқ.\n' +
                    'Sorpa қосымшасындағы баланстағы қаражаттың\n' +
                    'бар-жоғын қосымшаның басты бетінде көре\n' +
                    'аласыз. Sorpa қызметімен жұмыс істеуге\n' +
                    'қатысты кез келген сұрақтар бойынша\n' +
                    'келесі нөмірге хабарласуыңызға болады:\n' +
                    '+7 776 688 65 94.</span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>II.\n' +
                    'Құпиялылық</b></span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>1.\n' +
                    'Жалпы ережелер</b></span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Осы\n' +
                    'Құпиялылық саясатының (бұдан әрі -\n' +
                    'Саясат) нормалары Қызметті пайдаланушылардың\n' +
                    'Қызметті пайдалану барысында, соның\n' +
                    'ішінде оларды тіркеу рәсімінен өту\n' +
                    'кезінде және/немесе Қызметтің басқа\n' +
                    'функционалдығын пайдалану кезінде\n' +
                    'белгілі болуы мүмкін кез келген ақпаратқа\n' +
                    '(жеке деректерге) қатысты қолданылады.\n' +
                    'Sorpa қызметінің қызметі ашық сипатқа ие.\n' +
                    'Қызметті пайдалану (кез келген көлемде,\n' +
                    'нысанда және т.б.) мұндай Пайдаланушының\n' +
                    'осы Құпиялылық саясатының шарттарымен\n' +
                    'және мұндай Пайдаланушының жеке\n' +
                    'деректерін өңдеу тәртібімен толық\n' +
                    'келісетінін білдіреді. Осы Құпиялылық\n' +
                    'саясатын қабылдау Қызметте тіркеу\n' +
                    'рәсімінен өту, сондай-ақ Қызметпен\n' +
                    'танысуға бағытталған кез келген басқа\n' +
                    'әрекеттерді қамтиды және Қызметті\n' +
                    'немесе оның функционалдығын пайдалану\n' +
                    'болып табылады. Пайдаланушы осы Құпиялылық\n' +
                    'саясатының ережелерімен келіспеген\n' +
                    'жағдайда, ол Қызметті пайдалануды\n' +
                    'тоқтатуға міндетті. Қызметті пайдаланудың\n' +
                    'кез келген түрі Пайдаланушының осы\n' +
                    'Құпиялылық саясатының барлық ережелерін\n' +
                    'толық қабылдағанын білдіреді. Осы\n' +
                    'Құпиялылық саясаты тек Қызметке қатысты\n' +
                    'қолданылады. Қызмет әкімшілігі Қызметте\n' +
                    'қолжетімді сілтемелер бойынша Пайдаланушы\n' +
                    'өтуі мүмкін үшінші тұлғалардың\n' +
                    'интернет-ресурстары үшін жауап бермейді\n' +
                    'және бақылауды жүзеге асырмайды. Қызмет\n' +
                    'әкімшілігі Пайдаланушының Қызметке\n' +
                    'ұсынған жеке деректерінің дұрыстығын\n' +
                    'тексермейді, бірақ мұндай жеке деректердің\n' +
                    'дұрыстығын бақылау құқығын сақтайды.\n' +
                    'Егер осы Құпиялылық саясатының бір\n' +
                    'немесе бірнеше ережелері өз күшін\n' +
                    'жоғалтса, жарамсыз немесе заңды күші\n' +
                    'жоқ деп танылса, бұл осы Құпиялылық\n' +
                    'саясатының қалған ережелерінің\n' +
                    'жарамдылығына немесе қолданылуына әсер\n' +
                    'етпейді. Пайдаланушы осы Құпиялылық\n' +
                    'саясатын өзгерістердің болуын тексеру\n' +
                    'үшін жеке жауапкершілікке ие. Қызмет\n' +
                    'әкімшілігі өзінің жеке қалауы бойынша\n' +
                    'осы Құпиялылық саясатына алдын ала\n' +
                    'немесе кейін хабарламай өзгерістер\n' +
                    'енгізу немесе толықтыру құқығын өзіне\n' +
                    'қалдырады. Саясат Sorpa pay қызмет көрсету\n' +
                    'ережелерінің ажырамас бөлігі болып\n' +
                    'табылады. Үлкен әріптермен жазылған\n' +
                    'сөздер қызмет көрсету ережелерінде\n' +
                    'берілген мағынаға ие. Sorpa pay кез келген\n' +
                    'уақытта Саясатқа өзгерістер енгізу\n' +
                    'құқығын сақтайды. Өзгертілген Саясат\n' +
                    'редакциясы компанияның ТОО «Сорпа КЗ»\n' +
                    'сайтында жарияланған сәттен бастап\n' +
                    'күшіне енеді. Орналастыру күні құжатта\n' +
                    'көрсетілген. Интернетте жүзеге асырылатын\n' +
                    'қосылымдардың жалпы принциптеріне\n' +
                    'сәйкес берілетін және жүйелік журналдарда\n' +
                    '(мысалы, IP мекенжайы) бар деректер Sorpa\n' +
                    'Қызметімен техникалық мақсаттарда\n' +
                    'қолданылады, олар біздің қызметімізді\n' +
                    'басқаруға байланысты. Сонымен қатар,\n' +
                    'IP мекенжайлары статистикалық және\n' +
                    'демографиялық деректерді жинау үшін\n' +
                    'де қолданылады (мысалы, қосылым жүзеге\n' +
                    'асырылатын аймақ туралы). Sorpa мобильді\n' +
                    'қосымшасы жеке қажеттіліктер мен\n' +
                    'қызығушылықтарға сәйкес қызметтер мен\n' +
                    'мазмұнды ұсыну үшін cookies пайдалана\n' +
                    'алады. Браузерде cookies өшіру, негізінен,\n' +
                    'біздің қызметтерді пайдалануға кедергі\n' +
                    'жасамайды, бірақ кейбір қиындықтар\n' +
                    'туындауы мүмкін. Cookies сонымен қатар\n' +
                    'біздің қызметтерді пайдалануға қатысты\n' +
                    'статистикалық мәліметтерді жинау үшін\n' +
                    'пайдаланылады.</span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>ДЕРЕКТЕРДІ\n' +
                    'ТЕКСЕРУ, ӨЗГЕРТУ ЖӘНЕ ЖОЮ ҚҰҚЫҒЫ.\n' +
                    'ШЕКТЕУЛЕР</b></span></font></font></font></p>\n' +
                    '<p style="margin-bottom: 0.11in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Пайдаланушы\n' +
                    'немесе Серіктес кез келген уақытта өз\n' +
                    'деректерін қарауға құқылы, сонымен\n' +
                    'қатар оларды ТОО «Сорпа КЗ» келісімі\n' +
                    'бойынша жаңартуға немесе жоюға құқығы\n' +
                    'бар. Sorpa pay Мобильді қосымшасында біздің\n' +
                    'қызметтерді заңсыз пайдалану себебінен\n' +
                    'қатысуы тоқтатылған адамдарды қайта\n' +
                    'тіркеуден сақтану үшін, біз оларды қайта\n' +
                    'тіркеу мүмкіндігін болдырмау үшін\n' +
                    'қажетті деректерді жоюдан бас тартуымыз\n' +
                    'мүмкін. Деректерді жоюдан бас тарту\n' +
                    'заңмен көзделген жағдайларда да орын\n' +
                    'алуы мүмкін. Заңмен рұқсат етілген\n' +
                    'жағдайларда, ТОО «Сорпа КЗ» авторлық\n' +
                    '(немесе басқа) құқықтарды қорғауға\n' +
                    'байланысты Пайдаланушының немесе\n' +
                    'Серіктестің кейбір деректерін үшінші\n' +
                    'тұлғаларға жария етуі мүмкін (бірақ бұл\n' +
                    'құқық иесінің жазбаша өтініші негізінде\n' +
                    'ғана). Құқық иесі мұндай өтінішпен\n' +
                    'жүгінген кезде контент/өнім/қызмет\n' +
                    'бойынша авторлық (немесе басқа) құқықтарға\n' +
                    'ие екендігін дәлелдейтін құжаттарды\n' +
                    'ұсынуы тиіс. Бұл құқықтардың бұзылғаны\n' +
                    'туралы айқын күдік туындауы тиіс.\n' +
                    'Компания ТОО «Сорпа КЗ» сондай-ақ\n' +
                    'Пайдаланушының деректеріне қолжетімділікті\n' +
                    'Пайдаланушының қалауы немесе келісімі\n' +
                    'бойынша, немесе құқықтық акт негізінде\n' +
                    'деректерді алуға құқығы бар мемлекеттік\n' +
                    'органдардың талап етуі бойынша аша\n' +
                    'алады. Компания ТОО «Сорпа КЗ» жіберілген\n' +
                    'электрондық хабарламалар архивтеледі\n' +
                    '(сақталады) және белгілі бір жағдайларда\n' +
                    'дәлел ретінде пайдаланылуы мүмкін.\n' +
                    'Sorpa pay қызметі Пайдаланушылардың тіркеу\n' +
                    'кезінде және одан кейін ұсынған барлық\n' +
                    'деректерінің дұрыс екеніне сенім артады\n' +
                    'және Пайдаланушылар қате немесе ескірген\n' +
                    'деректерді өз бетімен түзетеді деп\n' +
                    'сенеді. Пайдаланушылар тек өздерінің\n' +
                    'деректерін хабарлайтынына, немесе\n' +
                    'деректер субъектісінің деректерін\n' +
                    'хабарлау үшін тиісті түрде ресімделген\n' +
                    'келісімін алғанына кепілдік береді.\n' +
                    'Осы ережелерді Пайдаланушылардың\n' +
                    'бұзғаны үшін ТОО «Сорпа КЗ» жауапкершілік\n' +
                    'көтермейді.</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>БАСҚА\n' +
                    'ИНТЕРНЕТ-САЙТТАРҒА СІЛТЕМЕЛЕР</b></span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Sorpa\n' +
                    'pay қызметі басқа сайттардың (соның ішінде\n' +
                    'Серіктестердің сайттарының) деректерді\n' +
                    'қорғау саясаты үшін жауап бермейді,\n' +
                    'олардағы сілтемелер арқылы Пайдаланушы\n' +
                    'Sorpa pay Қызметінен шыға алады. Біз өз\n' +
                    'деректеріңізді енгізбес бұрын тиісті\n' +
                    'сайттарда орналастырылған деректерді\n' +
                    'қорғау туралы мәлімдемелермен танысуды\n' +
                    'ұсынамыз. Пайдаланушы немесе Серіктес\n' +
                    'Sorpa pay қызметіне сілтеме арқылы\n' +
                    'орналастырған сайттың мазмұны үшін\n' +
                    'толық жауапкершілікті мойнына алады.</span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>ПАЙДАЛАНУШЫҒА\n' +
                    'НЕМЕСЕ СЕРІКТЕСКЕ ЖІБЕРІЛЕТІН ХАБАРЛАМАЛАР\n' +
                    '(ХАБАРЛАМАЛАР, ХАБАРЛАНДЫРУЛАР)</b></span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Sorpa\n' +
                    'pay қызметі барлық Пайдаланушыларға\n' +
                    'Қызметтегі маңызды өзгерістер мен\n' +
                    'жақсартулар туралы ақпаратты, сондай-ақ\n' +
                    'Қызмет жұмысына тікелей қатысты басқа\n' +
                    'мазмұнды электрондық пошта хабарламаларын\n' +
                    '(push хабарландырулар) жіберу құқығын\n' +
                    'сақтайды. Sorpa pay сондай-ақ коммерциялық\n' +
                    'сипаттағы электрондық хабарламаларды,\n' +
                    'соның ішінде жарнамалық хабарламаларды\n' +
                    'жіберуі мүмкін. Жарнамалық және басқа\n' +
                    'да коммерциялық сипаттағы хабарламалар\n' +
                    'жүйелік есеп жазбасына немесе одан\n' +
                    'жіберілген электрондық хабарламаларға\n' +
                    'қоса берілуі мүмкін.</span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>ЖАУАПКЕРШІЛІКТІ\n' +
                    'ШЕКТЕУ</b></span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Біз\n' +
                    'осы Құпиялылық саясатының сақталуын\n' +
                    'қамтамасыз ету үшін барлық мүмкіндіктерді\n' +
                    'жасаймыз, алайда, біздің ықпалымыздан\n' +
                    'тыс факторлардың әсерінен ақпараттың\n' +
                    'ашылуына әкелетін жағдайларда ақпараттың\n' +
                    'сақталуына кепілдік бере алмаймыз.\n' +
                    'Sorpa pay мобильді қосымшасы және ондағы\n' +
                    'барлық ақпарат “қандай бар солай”\n' +
                    'қағидасы бойынша ұсынылған және ешқандай\n' +
                    'кепілдіктер берілмейді. Біз қол жеткізу\n' +
                    'шектеулерінен туындаған кез келген\n' +
                    'қолайсыздықтар немесе кез келген\n' +
                    'шығындар үшін жауапкершілік көтермейміз.</span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>ДАУЛАРДЫ\n' +
                    'ШЕШУ</b></span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Пайдаланушы\n' +
                    'мен Қызмет әкімшілігі арасындағы\n' +
                    'қатынастардан туындайтын даулар бойынша\n' +
                    'сотқа талап арыз беруге дейінгі міндетті\n' +
                    'шарттар: 1) келіссөздер. Даулы жағдайды\n' +
                    'шешу мүмкін болмаған жағдайда, 2) дауды\n' +
                    'өз еркімен шешу туралы жазбаша ұсыныс\n' +
                    'беру. Талапты алған тарап талапты алған\n' +
                    'күннен бастап 30 (отыз) күнтізбелік күн\n' +
                    'ішінде талапты қарау нәтижелері туралы\n' +
                    'талап иесін жазбаша түрде хабардар\n' +
                    'етеді. Талап хаттары тараптармен курьер\n' +
                    'арқылы немесе тараптардың орналасқан\n' +
                    'жері бойынша мекенжайға жеткізілу\n' +
                    'туралы хабарламамен бірге тапсырыс\n' +
                    'пошта арқылы жіберіледі. Талап хаттарын\n' +
                    'басқа тәсілдермен жіберуге жол берілмейді.\n' +
                    'Келісімге қол жеткізілмеген жағдайда,\n' +
                    'дау қолданыстағы ҚР заңнамасына сәйкес\n' +
                    'сот қарауына беріледі. Осы Саясатқа\n' +
                    'және Пайдаланушы мен Қызмет әкімшілігі\n' +
                    'арасындағы қатынастарға қолданыстағы\n' +
                    'ҚР заңнамасы қолданылады.</span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>2.\n' +
                    'Байланыс ақпараты</b></span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Осы\n' +
                    'саясатқа қатысты сұрақтар бойынша\n' +
                    'келесі мекенжайға хабарласуыңызды\n' +
                    'сұраймыз: info@sorpa.com</span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Бұл\n' +
                    'Құпиялылық саясаты Қызметті пайдаланған\n' +
                    'кезде сіздің ақпараттарыңызды жинау,\n' +
                    'пайдалану және ашу туралы біздің\n' +
                    'саясатымыз бен процедураларымызды\n' +
                    'сипаттайды және сіздің құпиялылық\n' +
                    'құқықтарыңызды және заң сізді қалай\n' +
                    'қорғайтынын түсіндіреді.</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Біз\n' +
                    'сіздің Қызметті пайдалануды жақсарту\n' +
                    'үшін сіздің жеке деректеріңізді\n' +
                    'пайдаланамыз. Қызметті пайдалану арқылы\n' +
                    'сіз осы Құпиялылық саясатына сәйкес\n' +
                    'ақпарат жинауға және пайдалануға келісім\n' +
                    'бересіз. Бұл Құпиялылық саясаты тегін\n' +
                    'құпиялылық саясатын генерациялаушы\n' +
                    'құралмен жасалған.</span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Түсініктемелер\n' +
                    'және Анықтамалар</b></span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Түсініктеме:</b></span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\n' +
                    'Үлкен әріптермен жазылған сөздер келесі\n' +
                    'жағдайларда анықталған мағыналарға\n' +
                    'ие. Келесі анықтамалар бірлік пен көптік\n' +
                    'мағыналарына қарамастан сол мағынаға\n' +
                    'ие болады.</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Анықтамалар:</b></span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\n' +
                    'Осы Құпиялылық саясаты мақсаттары үшін:</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Аккаунт</b></span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\n' +
                    '– біздің Қызметке немесе оның бөліктеріне\n' +
                    'кіру үшін сіз үшін жасалған бірегей\n' +
                    'аккаунт.</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Филиал</b></span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\n' +
                    '– басқа тараптың бақылауында, бақылауында\n' +
                    'немесе жалпы бақылауында болатын ұйым,\n' +
                    'мұнда “бақылау” директорлар немесе\n' +
                    'басқа басқарушы органды сайлау құқығын\n' +
                    'беретін акциялардың 50% немесе одан да\n' +
                    'көп үлесіне иелік етеді.</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Қосымша</b></span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\n' +
                    'Sorpa-ға, Компания ұсынған бағдарламалық\n' +
                    'жасақтамаға қатысты.</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Компания</b></span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\n' +
                    '(сондай-ақ “Компания”, “Біз”, “Біз”\n' +
                    'немесе “Біздің” деп аталатын) ТОО\n' +
                    '«Сорпа КЗ», Қазақстан, Алматы қаласы,\n' +
                    'Әуезов ауданы, 6-шағын аудан, 58-үй,\n' +
                    '23-пәтер, пошта индексі A10A6X8 қатысты.</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Мемлекет</b></span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\n' +
                    'Қазақстанға қатысты.</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Құрылғы</b></span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\n' +
                    'Қызметке қол жеткізе алатын кез келген\n' +
                    'құрылғыны білдіреді, мысалы, компьютер,\n' +
                    'ұялы телефон немесе сандық планшет.</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Жеке\n' +
                    'деректер</b></span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\n' +
                    '– бұл белгілі бір немесе анықталатын\n' +
                    'жеке тұлғаға қатысты кез келген ақпарат.</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Қызмет</b></span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\n' +
                    'Қосымшаға қатысты.</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Қызмет\n' +
                    'көрсетуші</b></span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\n' +
                    'Компанияның атынан деректерді өңдейтін\n' +
                    'кез келген жеке немесе заңды тұлға. Бұл\n' +
                    'Компания атынан Қызмет көрсету, Қызметпен\n' +
                    'қамтамасыз ету немесе Компанияға\n' +
                    'Қызметті қалай пайдаланылатынын талдауда\n' +
                    'көмек көрсету үшін Компаниямен жалданған\n' +
                    'компаниялар немесе жеке тұлғаларға\n' +
                    'қатысты.</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Қолдану\n' +
                    'деректері</b></span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\n' +
                    'автоматты түрде жиналған деректерге\n' +
                    'немесе Қызметті пайдалану кезінде\n' +
                    'немесе Қызмет инфрақұрылымынан (мысалы,\n' +
                    'бетке кіру ұзақтығы) туындаған деректерге\n' +
                    'қатысты.</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Сіз</b></span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\n' +
                    'Қызметке қол жеткізетін немесе оны\n' +
                    'пайдаланатын жеке тұлғаны, не Қызметке\n' +
                    'қол жеткізетін немесе оны пайдаланатын\n' +
                    'жеке тұлғаның атынан компанияны немесе\n' +
                    'басқа заңды тұлғаны білдіреді, жағдайға\n' +
                    'байланысты.</span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Жеке\n' +
                    'деректерді жинау және пайдалану</b></span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Жиналатын\n' +
                    'деректер түрлері</b></span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Жеке\n' +
                    'деректер:</b></span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\n' +
                    'Қызметімізді пайдаланған кезде біз\n' +
                    'сізден сізбен байланысу немесе сізді\n' +
                    'сәйкестендіру үшін пайдаланылуы мүмкін\n' +
                    'белгілі бір жеке ақпаратты беруіңізді\n' +
                    'сұрауымыз мүмкін. Жеке ақпарат мыналарды\n' +
                    'қамтуы мүмкін, бірақ олармен шектелмейді:</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Қолдану\n' +
                    'деректері:</b></span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\n' +
                    'Қолдану деректері Қызметті пайдалану\n' +
                    'кезінде автоматты түрде жиналады.</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Қолдану\n' +
                    'деректері сіздің құрылғыңыздың IP\n' +
                    'мекенжайы (мысалы, IP мекенжайы), шолғыш\n' +
                    'түрі, шолғыш нұсқасы, біздің Қызметтегі\n' +
                    'беттеріңіз, кіру уақыты мен күні, сол\n' +
                    'беттерде өткізген уақытыңыз, бірегей\n' +
                    'құрылғы идентификаторлары және басқа\n' +
                    'диагностикалық деректер сияқты ақпаратты\n' +
                    'қамтуы мүмкін.</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Қызметке\n' +
                    'мобильді құрылғы арқылы қол жеткізгенде,\n' +
                    'біз сіздің мобильді құрылғыңыздың түрі,\n' +
                    'пайдаланылатын бірегей мобильді құрылғы\n' +
                    'идентификаторы, мобильді құрылғыңыздың\n' +
                    'IP мекенжайы, мобильді операциялық\n' +
                    'жүйеңіз, пайдаланатын мобильді\n' +
                    'интернет-шолғыш түрі сияқты белгілі\n' +
                    'бір ақпаратты автоматты түрде жинауымыз\n' +
                    'мүмкін. бірегей құрылғы идентификаторлары\n' +
                    'және басқа диагностикалық деректер.</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Біз\n' +
                    'сіздің шолғышыңыз біздің Қызметімізге\n' +
                    'әр кірген сайын немесе сіз Қызметке\n' +
                    'мобильді құрылғы арқылы кірген сайын\n' +
                    'жіберетін ақпаратты жинай аламыз.</span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Қосымшаны\n' +
                    'пайдалану кезінде жиналатын ақпарат</b></span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Қосымшамызды\n' +
                    'пайдалану кезінде біз сіздің алдын ала\n' +
                    'келісіміңізбен келесі ақпаратты\n' +
                    'жинауымыз мүмкін:</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Сіздің\n' +
                    'құрылғыңыздың камерасы мен фотосурет\n' +
                    'кітапханасындағы кескіндер және басқа\n' +
                    'ақпарат</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Біз\n' +
                    'бұл ақпаратты Қызметіміздің мүмкіндіктерін\n' +
                    'қамтамасыз ету, оны жақсарту және теңшеу\n' +
                    'үшін пайдаланамыз. Ақпарат Компания\n' +
                    'серверлеріне және/немесе қызмет көрсетуші\n' +
                    'серверіне жүктелуі мүмкін немесе ол\n' +
                    'жай ғана құрылғыңызда сақталуы мүмкін.</span></font></font></font></p>\n' +
                    '<p style="margin-bottom: 0.11in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Сіз\n' +
                    'кез келген уақытта құрылғыңыздың\n' +
                    'параметрлері арқылы осы ақпаратқа қол\n' +
                    'жеткізуді рұқсат етуіңізге немесе\n' +
                    'шектей аласыз.</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Жеке\n' +
                    'деректерді пайдалану</b></span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Компания\n' +
                    'сіздің жеке деректеріңізді келесі\n' +
                    'мақсаттарда пайдалана алады:</span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-left: 0.18in; text-indent: -0.18in; margin-bottom: 0in">\n' +
                    '<font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\t•\t</span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Қызмет\n' +
                    'көрсету және қолдау:</b></span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\n' +
                    'Біз сіздің деректеріңізді Қызметімізді\n' +
                    'қамтамасыз ету және оны қолдау, оның\n' +
                    'ішінде Қызметімізді пайдалану мониторингін\n' +
                    'жүргізу үшін пайдаланамыз.</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-left: 0.18in; text-indent: -0.18in; margin-bottom: 0in">\n' +
                    '<font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\t•\t</span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Аккаунтты\n' +
                    'басқару:</b></span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\n' +
                    'Сізді Қызметтің тіркелген пайдаланушысы\n' +
                    'ретінде тіркеу және сізге қолжетімді\n' +
                    'Қызметтің әртүрлі мүмкіндіктеріне кіру\n' +
                    'үшін сіздің деректеріңізді пайдаланамыз.</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-left: 0.18in; text-indent: -0.18in; margin-bottom: 0in">\n' +
                    '<font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\t•\t</span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Шартты\n' +
                    'орындау:</b></span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\n' +
                    'Сіз сатып алған өнімдерді, тауарларды\n' +
                    'немесе қызметтерді жеткізу, сатып алу\n' +
                    'келісімшарттарын орындау, немесе Қызмет\n' +
                    'арқылы жасалған кез келген басқа\n' +
                    'келісімдерді орындау.</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-left: 0.18in; text-indent: -0.18in; margin-bottom: 0in">\n' +
                    '<font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\t•\t</span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Сізбен\n' +
                    'байланыс:</b></span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\n' +
                    'Біз сізбен электрондық пошта, телефон\n' +
                    'қоңыраулары, SMS немесе басқа да электрондық\n' +
                    'байланыс түрлері арқылы хабарласып,\n' +
                    'мобильді қосымшаның жаңартулары немесе\n' +
                    'қызметтің жұмыс істеуіне байланысты\n' +
                    'хабарламаларды жіберу үшін сіздің\n' +
                    'деректеріңізді пайдалана аламыз.</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-left: 0.18in; text-indent: -0.18in; margin-bottom: 0in">\n' +
                    '<font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\t•\t</span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Жаңалықтар\n' +
                    'мен ұсыныстар:</b></span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\n' +
                    'Сізге жаңалықтар, арнайы ұсыныстар және\n' +
                    'біз ұсынатын басқа тауарлар, қызметтер\n' +
                    'немесе оқиғалар туралы жалпы ақпаратты\n' +
                    'жіберу, егер сіз мұндай ақпараттан бас\n' +
                    'тартпасаңыз.</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-left: 0.18in; text-indent: -0.18in; margin-bottom: 0in">\n' +
                    '<font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\t•\t</span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Сұрауларыңызды\n' +
                    'басқару:</b></span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\n' +
                    'Сізден түскен кез келген сұрауларды\n' +
                    'өңдеу және басқару үшін сіздің\n' +
                    'деректеріңізді пайдаланамыз.</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-left: 0.18in; text-indent: -0.18in; margin-bottom: 0in">\n' +
                    '<font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\t•\t</span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Бизнес-трансферттер:</b></span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\n' +
                    'Біз сіздің деректеріңізді бірігу, сату,\n' +
                    'қайта ұйымдастыру немесе кез келген\n' +
                    'басқа бизнес-трансферттер жағдайында\n' +
                    'пайдалана аламыз, мұнда пайдаланушылар\n' +
                    'туралы деректер біздің активтеріміздің\n' +
                    'бірі болып табылады.</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-left: 0.18in; text-indent: -0.18in; margin-bottom: 0in">\n' +
                    '<font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\t•\t</span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Басқа\n' +
                    'мақсаттар:</b></span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\n' +
                    'Біз сіздің деректеріңізді деректерді\n' +
                    'талдау, Қызметімізді, өнімдерімізді,\n' +
                    'қызметтерімізді жақсарту және маркетингті\n' +
                    'жақсарту сияқты басқа мақсаттар үшін\n' +
                    'пайдалана аламыз.</span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Жеке\n' +
                    'ақпаратты ортақ пайдалану</b></span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Компания\n' +
                    'сіздің жеке ақпаратыңызды келесі\n' +
                    'жағдайларда бөлісе алады:</span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-left: 0.18in; text-indent: -0.18in; margin-bottom: 0in">\n' +
                    '<font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\t•\t</span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Қызмет\n' +
                    'көрсетушілер:</b></span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\n' +
                    'Біз сіздің деректеріңізді Қызметімізді\n' +
                    'мониторинг және талдау жасау үшін қызмет\n' +
                    'көрсетушілермен бөлісе аламыз.</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-left: 0.18in; text-indent: -0.18in; margin-bottom: 0in">\n' +
                    '<font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\t•\t</span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Бизнес-трансферттер:</b></span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\n' +
                    'Біз сіздің деректеріңізді компания\n' +
                    'активтерін сату, бірігу немесе қайта\n' +
                    'ұйымдастыру жағдайында бөлісе аламыз.</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-left: 0.18in; text-indent: -0.18in; margin-bottom: 0in">\n' +
                    '<font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\t•\t</span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Серіктестер:</b></span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\n' +
                    'Біз сіздің деректеріңізді біздің\n' +
                    'аффилиирленген компанияларымызбен\n' +
                    'бөлісе аламыз, онда олар біздің Құпиялылық\n' +
                    'саясатымызды сақтауға міндеттеледі.</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-left: 0.18in; text-indent: -0.18in; margin-bottom: 0in">\n' +
                    '<font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\t•\t</span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Басқа\n' +
                    'пайдаланушылар:</b></span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\n' +
                    'Сіз ортақ пайдаланатын кез келген\n' +
                    'деректер, мысалы, әлеуметтік желілерде\n' +
                    'немесе Қызметтің ашық аймақтарында\n' +
                    'басқа пайдаланушыларға көрінеді.</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-left: 0.18in; text-indent: -0.18in; margin-bottom: 0in">\n' +
                    '<font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\t•\t</span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Сіздің\n' +
                    'келісіміңіз:</b></span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\n' +
                    'Біз сіздің жеке ақпаратыңызды басқа\n' +
                    'мақсаттар үшін сіздің келісіміңізбен\n' +
                    'ашамыз.</span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Жеке\n' +
                    'деректерді сақтау</b></span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Компания\n' +
                    'сіздің жеке деректеріңізді осы Құпиялылық\n' +
                    'саясатында көрсетілген мақсаттарға\n' +
                    'жету үшін қажет болғанша сақтайды.\n' +
                    'Сондай-ақ, біз сіздің деректеріңізді\n' +
                    'құқықтық міндеттемелерімізді орындау\n' +
                    'үшін, дауларды шешу үшін және заңдық\n' +
                    'келісімдерімізді орындау үшін қажетті\n' +
                    'мерзімге дейін сақтаймыз.</span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Жеке\n' +
                    'деректерді беру</b></span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Сіздің\n' +
                    'деректеріңіз, соның ішінде жеке\n' +
                    'деректеріңіз, Компанияның кеңселерінде\n' +
                    'және деректерді өңдеуге қатысатын кез\n' +
                    'келген басқа жерде өңделуі мүмкін. Бұл\n' +
                    'ақпарат сіздің мемлекетіңізден тыс\n' +
                    'орналасқан компьютерлерге берілуі\n' +
                    'мүмкін, онда деректерді қорғау заңдары\n' +
                    'сіздің юрисдикцияңыздағы заңдардан\n' +
                    'өзгеше болуы мүмкін. Сіздің осы Құпиялылық\n' +
                    'саясатына келісіміңіз және мұндай\n' +
                    'ақпаратты беруіңіз бұл ақпараттың басқа\n' +
                    'елдерге берілуіне келісіміңізді\n' +
                    'білдіреді. Компания сіздің деректеріңіздің\n' +
                    'қауіпсіздігін қамтамасыз ету үшін\n' +
                    'барлық қажетті шараларды қабылдайды\n' +
                    'және деректердің қауіпсіздігін қамтамасыз\n' +
                    'ететін орынға немесе елге жеткізілгенге\n' +
                    'дейін сіздің жеке деректеріңізді қорғау\n' +
                    'үшін барлық қажетті шараларды қабылдайды.</span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Жеке\n' +
                    'деректерді жою</b></span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Сіз\n' +
                    'кез келген уақытта біз жинаған жеке\n' +
                    'деректерді жоюға немесе бізден оларды\n' +
                    'жоюға көмектесуді сұрауға құқылысыз.\n' +
                    'Қызметіміз сізге кейбір деректерді\n' +
                    'ішкі жүйеден жоюға мүмкіндік беруі\n' +
                    'мүмкін. Сіз өзіңіздің деректеріңізді\n' +
                    'кез келген уақытта жаңартуға, өзгертуге\n' +
                    'немесе жоюға болады, егер ол сіздің\n' +
                    'аккаунтыңызда болса, оны жасау үшін өз\n' +
                    'есептік жазбаңызға кіруіңіз керек және\n' +
                    'жеке деректеріңізді басқаруға мүмкіндік\n' +
                    'беретін есептік жазба параметрлерін\n' +
                    'қараңыз. Сондай-ақ, сіз бізбен хабарласып,\n' +
                    'бізден сіз берген кез келген жеке\n' +
                    'ақпаратқа кіру, оны түзету немесе жоюды\n' +
                    'сұрай аласыз. Бірақ кейбір жағдайларда\n' +
                    'біз заңдық міндеттемелерімізге байланысты\n' +
                    'белгілі бір ақпаратты сақтауға мәжбүр\n' +
                    'болуымыз мүмкін.</span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Жеке\n' +
                    'деректерді ашу</b></span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-left: 0.18in; text-indent: -0.18in; margin-bottom: 0in">\n' +
                    '<font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\t•\t</span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Бизнес\n' +
                    'операциялары:</b></span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\n' +
                    'Егер Компания бірігу, сатып алу немесе\n' +
                    'активтерді сату кезінде қатысатын\n' +
                    'болса, сіздің жеке деректеріңіз берілуі\n' +
                    'мүмкін. Сіздің жеке деректеріңіз басқа\n' +
                    'Құпиялылық саясатының нысаны болатындықтан,\n' +
                    'сізге алдын ала хабарланатын болады.</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-left: 0.18in; text-indent: -0.18in; margin-bottom: 0in">\n' +
                    '<font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\t•\t</span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Құқық\n' +
                    'қорғау органдары:</b></span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\n' +
                    'Кейбір жағдайларда Компания сіздің\n' +
                    'жеке деректеріңізді заң талаптарына\n' +
                    'немесе мемлекеттік органдардың заңды\n' +
                    'сұрауларына (мысалы, сот немесе мемлекеттік\n' +
                    'агенттік сұрауы бойынша) сәйкес ашуға\n' +
                    'міндетті болуы мүмкін.</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-left: 0.18in; text-indent: -0.18in; margin-bottom: 0in">\n' +
                    '<font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\t•\t</span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Басқа\n' +
                    'заңды талаптар:</b></span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\n' +
                    'Компания сіздің жеке деректеріңізді\n' +
                    'келесі жағдайларда аша алады:</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-left: 0.46in; text-indent: -0.46in; margin-bottom: 0in">\n' +
                    '<font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\t•\t</span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Құқықтық\n' +
                    'міндеттемелерді орындау</b></span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-left: 0.46in; text-indent: -0.46in; margin-bottom: 0in">\n' +
                    '<font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\t•\t</span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Компанияның\n' +
                    'құқықтары мен мүлкін қорғау</b></span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-left: 0.46in; text-indent: -0.46in; margin-bottom: 0in">\n' +
                    '<font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\t•\t</span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Қызметпен\n' +
                    'байланысты ықтимал құқық бұзушылықтарды\n' +
                    'болдырмау немесе тергеу</b></span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-left: 0.46in; text-indent: -0.46in; margin-bottom: 0in">\n' +
                    '<font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\t•\t</span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Қызмет\n' +
                    'пайдаланушыларының немесе қоғамның\n' +
                    'жеке қауіпсіздігін қорғау</b></span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-left: 0.46in; text-indent: -0.46in; margin-bottom: 0in">\n' +
                    '<font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">\t•\t</span></font></font></font><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Заңдық\n' +
                    'жауапкершіліктен қорғау</b></span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Жеке\n' +
                    'деректердің қауіпсіздігі</b></span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Сіздің\n' +
                    'жеке деректеріңіздің қауіпсіздігі біз\n' +
                    'үшін маңызды, бірақ Интернет арқылы\n' +
                    'деректерді беру немесе электрондық\n' +
                    'сақтау әдісі 100% қауіпсіз емес екенін\n' +
                    'есте сақтаңыз. Біз сіздің жеке\n' +
                    'деректеріңіздің қауіпсіздігін қамтамасыз\n' +
                    'ету үшін коммерциялық тұрғыдан қолайлы\n' +
                    'құралдарды қолдануға тырысқанымызбен,\n' +
                    'оның абсолютті қауіпсіздігіне кепілдік\n' +
                    'бере алмаймыз.</span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Балалардың\n' +
                    'құпиялылығы</b></span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Қызметіміз\n' +
                    '13 жастан кіші тұлғаларға арналмаған.\n' +
                    'Біз 13 жастан кіші тұлғалардан қасақана\n' +
                    'жеке ақпарат жинамаймыз. Егер сіз ата-ана\n' +
                    'немесе қамқоршы болсаңыз және балаңыздың\n' +
                    'бізге жеке деректер бергенін білсеңіз,\n' +
                    'бізбен хабарласыңыз. Егер біз 13 жастан\n' +
                    'кіші кез келген тұлғадан ата-ана\n' +
                    'келісімінсіз жеке деректерді жинағанымызды\n' +
                    'білсек, біз бұл ақпаратты серверлерімізден\n' +
                    'жою үшін шаралар қабылдаймыз. Егер\n' +
                    'сіздің еліңіз келісімнің құқықтық\n' +
                    'негізі ретінде ата-ана келісімін талап\n' +
                    'етсе, біз ақпаратты жинап, пайдаланар\n' +
                    'алдында ата-анаңыздың келісімін сұраймыз.</span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Басқа\n' +
                    'веб-сайттарға сілтемелер</b></span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Қызметіміз\n' +
                    'сізге тиесілі емес басқа веб-сайттарға\n' +
                    'сілтемелерді қамтуы мүмкін. Егер сіз\n' +
                    'үшінші тарап сілтемесін бассаңыз, сіз\n' +
                    'сол үшінші тараптың сайтына бағытталасыз.\n' +
                    'Біз сізден кез келген сайттың құпиялылық\n' +
                    'саясатына шолу жасауды ұсынамыз. Біз\n' +
                    'үшінші тарап сайттары немесе қызметтерінің\n' +
                    'мазмұны, құпиялылық саясаты немесе\n' +
                    'практикасы үшін жауапкершілік көтермейміз.</span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Құпиялылық\n' +
                    'саясатына өзгерістер</b></span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Біз\n' +
                    'құпиялылық саясатымызды мезгіл-мезгіл\n' +
                    'жаңартып отыруымыз мүмкін. Кез келген\n' +
                    'өзгерістер туралы сізге жаңа Құпиялылық\n' +
                    'саясатын осы бетте жариялау арқылы\n' +
                    'хабарлаймыз. Өзгерістер күшіне енгенге\n' +
                    'дейін сізге электрондық пошта арқылы\n' +
                    'және/немесе Қызметімізде елеулі\n' +
                    'хабарландыру арқылы хабарлаймыз және\n' +
                    'осы Құпиялылық саясатының жоғарғы\n' +
                    'бөлігінде “Соңғы жаңарту” күнін\n' +
                    'жаңартамыз. Сізге біздің Құпиялылық\n' +
                    'саясатына өзгерістер бар-жоғын үнемі\n' +
                    'қарап шығуға кеңес береміз. Біздің\n' +
                    'Құпиялылық саясатына кез келген\n' +
                    'өзгерістер осы бетте жарияланғаннан\n' +
                    'кейін күшіне енеді.</span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Байланыс</b></span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Егер\n' +
                    'сізде осы Құпиялылық саясатына қатысты\n' +
                    'сұрақтарыңыз болса, бізге келесі мекенжай\n' +
                    'бойынша хабарласыңыз: info@sorpa.com</span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU"><b>Компанияның\n' +
                    'реквизиттері</b></span></font></font></font></p>\n' +
                    '<p lang="ru-RU" style="line-height: 135%; margin-bottom: 0in"><br/>\n' +
                    '\n' +
                    '</p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Атауы:\n' +
                    'ТОО «Сорпа КЗ»</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Мекенжайы:\n' +
                    'Қазақстан, Алматы қаласы, Әуезов ауданы,\n' +
                    '6-шағын аудан, 58-үй, 23-пәтер, пошта индексі\n' +
                    'A10A6X8</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">БИН:\n' +
                    '240440034473</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">БИК:\n' +
                    'BRKEKZKA</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">КБе:\n' +
                    '19</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Банк:\n' +
                    'AO «Bereke Bank»</span></font></font></font></p>\n' +
                    '<p style="line-height: 135%; margin-bottom: 0in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Есепшот\n' +
                    'нөмірі: KZ37914032203KZ025AZ (KZT)</span></font></font></font></p>\n' +
                    '<p style="margin-bottom: 0.11in"><font color="#0e0e0e"><font face=""><font size="4" style="font-size: 14pt"><span lang="ru-RU">Директор:\n' +
                    'Тулеубаева Ш.У.</span></font></font></font></p>'
            }}/>
        )
        return (
            <div className={"container"} style={{gap: 12}}>
                <p className={"font-bold w-full"}>Политика конфиденциальности и условия оплаты</p>
                <p className={"font-bold w-full"}>I. Платежи.</p>
                <p className={"font-bold w-full"}>1. Оплата банковской картой онлайн</p>
                Сервис Sorpa pay может принять оплату с баланса вашего клиента из приложения Sorpa.
                <br/>Вам необходимо выставить счет и выпустить QR-code, чтобы ваш клиент мог оплатить его через
                приложение Sorpa, баллами sorpa.
                <p className={"font-bold w-full"}>2. Правила покупки услуги</p>
                Принимая оплату выбранного тарифа на сервисе Sorpa pay, вы подтверждаете согласие с договором публичной
                оферты, расположенным в мобильном приложении Sorpa pay.
                <p className={"font-bold w-full"}>3. Возврат денежных средств</p>
                Для возврата денежных средств на банковскую карту необходимо заполнить «Заявление о возврате денежных
                средств», которое высылается по требованию компанией на электронный адрес, и оправить его вместе с
                приложением копии документа, удостоверяющего личность, по адресу Info@sorpa.com
                <br/>Возврат денежных средств будет осуществлен на банковскую карту в течение 10 рабочих дней со дня
                получения «Заявления о возврате денежных средств» Компанией при наличии обоснованных претензий по
                оказанию услуг.
                <br/>Для возврата денежных средств по операциям, проведенным с ошибками, необходимо обратиться с
                письменным заявлением и приложением копии документа, удостоверяющего личность, и чеков/квитанций,
                подтверждающих ошибочное списание. Данное заявление необходимо направить по адресу Info@sorpa.com .
                <br/>Сумма возврата будет равняться сумме покупки за вычетом стоимости оказанных услуг. Срок
                рассмотрения Заявления и возврата денежных средств начинает исчисляться с момента получения Компанией
                Заявления и рассчитывается в рабочих днях без учета праздников/выходных дней.
                <p className={"font-bold w-full"}>4. Причины отказа в совершении платежа:</p>
                • приложение не предназначена для совершения платежей через интернет, о чем можно узнать, обратившись в
                Ваш Банк-эмитент;
                <br/>• недостаточно средств для оплаты на балансе. Подробнее о наличии средств на балансе приложение
                Sorpa Вы можете узнать, посмотрев его внутри приложения Sorpa, на главной странице;
                <br/>По вопросам оплаты с помощью банковской карты и иным вопросам, связанным с работой сервиса Sorpa,
                Вы можете обратиться по телефону: +7 776 688 65 94.
                <p className={"font-bold w-full"}>II. Конфиденциальность</p>
                <p className={"font-bold w-full"}>1. Общие положения</p>
                Нормы настоящей Политики конфиденциальности (далее – Политика) действуют в отношении любой информации
                (персональных данных), которая может стать известной Сервису и его Администрации в процессе
                использования Сервиса Пользователями, в том числе в процессе прохождения ими процедуры регистрации и/или
                при любом другом использовании функционала Сервиса, через защищенные страницы. Деятельность сервиса
                Sorpa носит открытый характер. Использование Сервиса (в любом объеме, форме и т.д.) означает полное
                согласие такого Пользователя с условиями настоящей Политики конфиденциальности и порядком обработки
                персональных данных такого Пользователя. Акцептом настоящей Политики конфиденциальности является
                прохождение процедуры регистрации в Сервисе, а также любые другие действия, которые направлены на
                ознакомление с Сервисом, и/или использованием его функционала, доступного без регистрации, а также
                действия, которые могут восприниматься Администрацией Сервиса как такие, которые направлены на
                использование Сервиса или его функционала. В случае, если Пользователь не согласен с положениями,
                которые содержатся в нормах Политики конфиденциальности, он обязан прекратить использование Сервиса.
                Любое использование Сервиса Пользователем означает его полное согласие и принятие всех положений
                настоящей Политики конфиденциальности. Настоящая Политика конфиденциальности применяется только к
                Сервису. Администрация Сервиса не контролирует и не несет ответственность за интернет-ресурсы третьих
                лиц, на которые Пользователь может перейти по ссылкам, доступным в Сервисе. Администрация Сервиса не
                проверяет достоверность персональных данных, предоставляемых Пользователем Сервису, но оставляет за
                собой право мониторинга достоверности таких персональных данных. В случае, если одно или несколько
                положений настоящей Политики конфиденциальности утратит свою силу или окажется недействительным, или не
                имеющим юридической силы, это не оказывает влияния на действительность или применимость остальных
                положений настоящей Политики конфиденциальности. Пользователь несет персональную ответственность за
                проверку настоящей Политики конфиденциальности на наличие изменений в ней. Администрация Сервиса
                оставляет за собой право по своему личному усмотрению изменять или дополнять настоящую Политику
                конфиденциальности в любое время без предварительного и последующего уведомления. Политика является
                неотъемлемой частью Правил оказания услуг Sorpa pay. Слова с прописной буквы используются в том же
                значении, что и в Правилах оказания услуг. Sorpa pay вправе в любое время вносить изменения в Политику.
                Измененная редакция Политики вступает в силу с момента ее размещения на сайте компании ТОО «Сорпа КЗ».
                Дата размещения указывается в документе. Данные, которые передаются в силу общих принципов соединений,
                реализуемых в Интернете, и содержатся в системных логах (например, адрес IP), используются Сервисом
                Sorpa в технических целях, связанных с администрированием нашего сервиса. Кроме того, адреса IP
                используются для сбора статистических и демографических данных (например, о регионе, из которого
                реализуется соединение). Мобильное приложение Sorpa может использовать cookies для предоставления услуг
                и контента в соответствии с индивидуальными потребностями и интересами. Отключение cookies в браузере в
                основном не будет блокировать пользование нашими услугами, но может вызвать определенные сложности.
                Cookies также используются для сбора статистических сведений об использовании наших сервисов.
                <p className={"font-bold w-full"}>ПРАВО НА ПРОВЕРКУ, ИЗМЕНЕНИЕ И УДАЛЕНИЕ ДАННЫХ. ОГРАНИЧЕНИЯ</p>
                Пользователь или Партнер имеет право на просмотр своих данных в любой момент, а также располагает
                возможностью обновить или удалить их по согласованию с ТОО «Сорпа КЗ». Во избежание повторной
                регистрации лиц, участие которых на Мобильном приложении Sorpa pay было прекращено по причине
                неправомерного использования наших услуг, мы можем отказать в удалении данных, необходимых для
                блокировки возможности повторной регистрации. Отказ в удалении данных может также иметь место в случаях,
                предусмотренных законодательством. В случаях, когда это допускается законодательством, ТОО «Сорпа КЗ»
                может также раскрыть определенные данные Пользователя или Партнера третьим лицам, если это связано с
                защитой авторских (или других) прав этих третьих лиц (но исключительно по письменному заявлению
                правообладателя), либо на основании договора между компанией ТОО «Сорпа КЗ» и третьим лицом в отношении
                Сервиса (такой договор обязательно содержит условие об обеспечении третьим лицом конфиденциальности и
                безопасности). Правообладатель, подавший такое заявление, обязан представить документальные
                доказательства факта обладания авторскими (или другими) правами на контент/продукт/услугу. Должно также
                возникнуть явное подозрение на нарушение этих прав. Компания ТОО «Сорпа КЗ» может также открыть доступ к
                имеющимся у нее данным Пользователя по желанию или при согласии самого Пользователя, либо по требованию
                государственных органов, обладающих правом на получение данных на основании нормативно-правового акта.
                Электронные сообщения, направляемые в компанию ТОО «Сорпа КЗ», архивируются (сохраняются) и могут в
                определенных случаях использоваться в качестве доказательства. Сервис Sorpa pay полагается на то, что
                все предоставленные Пользователями при регистрации и впоследствии данные достоверны и Пользователи
                самостоятельно исправляют неверные или устаревшие данные. Пользователи также гарантируют, что они
                сообщают только свои собственные данные, либо получили надлежащим образом оформленное согласие третьего
                лица - субъекта данных на сообщение его данных. За нарушение этих правил Пользователями ТОО «Сорпа КЗ»
                ответственности не несет.
                <p className={"font-bold w-full"}>ССЫЛКИ НА ДРУГИЕ ИНТЕРНЕТ-САЙТЫ</p>
                Сервис Sorpa pay не несет ответственности за политику защиты данных, которая применяется владельцами или
                администраторами других сайтов (в т.ч. сайтов Партнеров), на которые Пользователь переходит по ссылкам,
                размещенным на Сервисе Sorpa pay. Мы рекомендуем ознакомиться с содержанием заявлений о защите данных,
                которые размещены на соответствующих сайтах, до ввода своих данных. Пользователь или Партнер несет
                полную ответственность за контент, который находится на сайте, на который ведет ссылка, размещенная ими
                на Sorpa pay.
                <br/>СВЕДЕНИЯ (сообщения, уведомления), КОТОРЫЕ ОТПРАВЛЯЮТСЯ ПОЛЬЗОВАТЕЛЮ ИЛИ ПАРТНЕРУ
                <br/>Сервис Sorpa pay оставляет за собой право отправлять всем Пользователям электронные письма (push
                уведомления) с информацией о важных изменениях и улучшениях в Сервисе, а также иного содержания
                непосредственно касающихся работы сервиса. Sorpa pay может также отправлять электронные сообщения
                коммерческого характера, в частности рекламные сообщения. Рекламные и другие сообщения коммерческого
                характера могут также прилагаться к электронным сообщениям, направленным на учетную запись системы или
                отправленные с нее.
                <br/>Ограничение ответственности
                <br/>Мы делаем все возможное для соблюдения настоящей политики конфиденциальности, однако, мы не можем
                гарантировать сохранность информации в случае воздействия факторов. находящихся вне нашего влияния,
                результатом действия которых станет раскрытие информации.Мобильное приложение Sorpa pay и вся
                размещенная на нем информация представлены по принципу "как есть” без каких-либо гарантий. Мы не несем
                ответственности за неблагоприятные последствия, а также за любые убытки, причиненные вследствие
                ограничения доступа к сайту Сервиса.
                <p className={"font-bold w-full"}>РАЗРЕШЕНИЕ СПОРОВ</p>
                До обращения в суд с иском по спорам, возникающим из отношений между Пользователем и Администрацией
                Сервиса, обязательным условиями является 1) переговоры. Если не было достигнуто разрешение спорной
                ситуации, то 2) предъявление письменного предложения о добровольном урегулировании спора. Получатель
                претензии в течение 30ти календарных дней со дня получения претензии, письменно уведомляет заявителя
                претензии о результатах рассмотрения претензии. Претензионные письма направляются сторонами курьером,
                либо заказным почтовым отправлением с уведомлением о вручении последнего адресату по местонахождению
                сторон. Не допускается направление сторонами претензионных писем иными способами. При недостижении
                соглашения спор будет передан на рассмотрение в судебный орган в соответствии с действующим
                законодательством РК. К настоящей Политике и отношениям между Пользователем и Администрацией Сервиса
                применяется действующее законодательство РК.
                <p className={"font-bold w-full"}>2. Контакты</p>
                По вопросам, касающимся настоящей политики, просьба обращаться по адресу Info@sorpa.com
                Эта Политика конфиденциальности описывает наши политики и процедуры в отношении сбора, использования и
                раскрытия ваших сведений при использовании Сервиса и информирует вас о ваших правах на
                конфиденциальность и о том, как закон защищает вас.
                <br/>Мы используем ваши персональные данные для предоставления и улучшения Сервиса. Используя Сервис, вы
                соглашаетесь на сбор и использование информации в соответствии с настоящей Политикой конфиденциальности.
                Эта Политика конфиденциальности была создана с помощью бесплатного генератора политики
                конфиденциальности.
                <p className={"font-bold w-full"}>Толкование и Определения</p>
                Толкование: Слова, первая буква которых написана с большой буквы, имеют значения, определенные в
                следующих условиях. Следующие определения имеют тот же смысл независимо от того, появляются ли они в
                единственном числе или во множественном числе.
                <p className={"font-bold w-full"}>Определения: для целей настоящей Политики конфиденциальности:</p>
                Аккаунт означает уникальный аккаунт, созданный для вас для доступа к нашему Сервису или его частям.
                <br/>Филиал означает сущность, которая контролирует, контролируется или находится под общим контролем с
                другой стороной, где "контроль" означает владение 50% или более акций, долей в уставном капитале или
                других ценных бумаг, дающих право голоса при избрании директоров или иного управляющего органа.
                <br/>Приложение относится к sorpa, программному обеспечению, предоставляемому Компанией.
                <br/>Компания (называемая также "Компанией", "Мы", "Нами" или "Нашей" в настоящем Соглашении) относится
                к ТОО «Сорпа КЗ», Казахстан, город Алматы, Ауэзовский район, Микрорайон 6, дом 58, кв. 23, почтовый
                индекс A10A6X8.
                <br/>Страна относится к: Казахстан
                <br/>Устройство означает любое устройство, которое может получить доступ к Сервису, такое как компьютер,
                мобильный телефон или цифровой планшет.
                <br/>Персональные данные — это любая информация, которая относится к определенному или определяемому
                физическому лицу.
                <br/>Сервис относится к Приложению.
                <br/>Поставщик услуг означает любое физическое или юридическое лицо, которое обрабатывает данные от
                имени Компании. Это относится к компаниям или частным лицам, нанятым Компанией для оказания услуг,
                предоставления Сервиса от имени Компании, выполнения услуг, связанных с Сервисом, или помощи Компании в
                анализе того, как используется Сервис.
                <br/>Данные использования относятся к данным, собираемым автоматически, либо сгенерированным при
                использовании Сервиса, либо из самой инфраструктуры Сервиса (например, продолжительность посещения
                страницы).
                <br/>Вы означаете физическое лицо, получающее доступ к Сервису или использующее его, либо компанию или
                другое юридическое лицо, от имени которого такое физическое лицо получает доступ к Сервису или
                использует его, в зависимости от случая.
                <br/>Сбор и использование ваших персональных данных
                <p className={"font-bold w-full"}>Типы собираемых данных</p>
                Персональные данные: при использовании нашего Сервиса мы можем попросить вас предоставить нам
                определенную личную информацию, которая может использоваться для связи или идентификации вас. Личная
                информация может включать, но не ограничивается:
                <p className={"font-bold w-full"}>Данные использования</p>
                Данные использования собираются автоматически при использовании Сервиса.
                <br/>Данные использования могут включать информацию, такую как IP-адрес вашего устройства (например,
                IP-адрес), тип браузера, версия браузера, страницы нашего Сервиса, которые вы посещаете, время и дата
                вашего посещения, время, проведенное на этих страницах, уникальные идентификаторы устройств и другие
                диагностические данные.
                <br/>Когда вы получаете доступ к Сервису через мобильное устройство, мы можем автоматически собирать
                определенную информацию, включая, но не ограничиваясь, тип мобильного устройства, используемый вами
                уникальный идентификатор мобильного устройства, IP-адрес вашего мобильного устройства, вашу мобильную
                операционную систему, тип мобильного браузера Интернета, который вы используете, уникальные
                идентификаторы устройств и другие диагностические данные.
                <br/>Мы также можем собирать информацию, которую ваш браузер отправляет каждый раз, когда вы посещаете
                наш Сервис или когда вы получаете доступ к Сервису через мобильное устройство.
                <br/>Информация, собираемая при использовании Приложения
                <br/>При использовании нашего Приложения мы можем собирать, с вашего предварительного согласия,
                следующую информацию:
                <br/>Изображения и другую информацию с камеры и фото-библиотеки вашего устройства
                <br/>Мы используем эту информацию для предоставления функций нашего Сервиса, для его улучшения и
                настройки. Информация может быть загружена на серверы Компании и/или сервер поставщика услуг, либо она
                может просто храниться на вашем устройстве.
                <br/>Вы можете в любое время разрешить или запретить доступ к этой информации через настройки вашего
                устройства.
                <p className={"font-bold w-full"}>Использование ваших персональных данных</p>
                Компания может использовать ваши персональные данные дляследующих целей:
                <br/>Для предоставления и поддержки нашего Сервиса, включая мониторинг использования нашего Сервиса.
                <br/>Для управления вашим аккаунтом: для управления вашей регистрацией в качестве пользователя Сервиса.
                Предоставленные вами персональные данные могут дать вам доступ к различным функциям Сервиса, доступным
                вам как зарегистрированному пользователю.
                <br/>Для исполнения контракта: разработка, соблюдение и исполнение договора купли-продажи продуктов,
                товаров или услуг, которые вы приобрели, или любого другого договора с нами через Сервис.
                <br/>Для связи с вами: чтобы связаться с вами по электронной почте, телефонными звонками, SMS или
                другими эквивалентными формами электронной связи, такими как уведомления мобильного приложения об
                обновлениях или информационные сообщения, связанные с функциональными возможностями, продуктами или
                услугами, включая обновления безопасности, когда это необходимо или разумно для их внедрения.
                <br/>Для предоставления вам новостей, специальных предложений и общей информации о других товарах,
                услугах и событиях, которые мы предлагаем и которые аналогичны тем, которые вы уже приобрели или о
                которых спрашивали, если вы не отказались от получения такой информации.
                <br/>Для управления вашими запросами: для обработки и управления вашими запросами к нам.
                <br/>Для бизнес-передач: Мы можем использовать вашу информацию для оценки или проведения слияния,
                выделения, реструктуризации, реорганизации, ликвидации или другой продажи или передачи части или всех
                наших активов, будь то как идущего дела или в рамках банкротства, ликвидации или аналогичной процедуры,
                в которой персональные данные, хранящиеся нами о пользователях нашего Сервиса, являются одним из
                передаваемых активов.
                <br/>Для других целей: Мы можем использовать вашу информацию для других целей, таких как анализ данных,
                определение тенденций использования, определение эффективности наших рекламных кампаний и оценка, и
                улучшение нашего Сервиса, продуктов, услуг, маркетинга и вашего опыта.
                <br/>Мы можем делиться вашей личной информацией в следующих ситуациях:
                <br/>С поставщиками услуг: Мы можем делиться вашей персональной информацией с поставщиками услуг для
                мониторинга и анализа использования нашего Сервиса, чтобы связаться с вами.
                <br/>Для бизнес-передач: Мы можем делиться или передавать вашу персональную информацию в связи с
                проведением слияния, продажи активов компании, финансирования или приобретения всего или части нашего
                бизнеса другой компанией.
                <br/>С аффилированными лицами: Мы можем делиться вашей информацией с нашими аффилированными лицами, в
                этом случае мы потребуем, чтобы эти аффилированные лица соблюдали настоящую Политику конфиденциальности.
                К аффилированным лицам относятся наша материнская компания и любые другие дочерние компании, партнеры по
                совместному предприятию или другие компании, которые мы контролируем или которые находятся под общим
                контролем с нами.
                <br/>С бизнес-партнерами: Мы можем делиться вашей информацией с нашими бизнес-партнерами, чтобы
                предложить вам определенные продукты, услуги или акции.
                <br/>С другими пользователями: когда вы делитесь персональной информацией или иным образом
                взаимодействуете в общедоступных областях с другими пользователями, такая информация может быть
                просмотрена всеми пользователями и может быть общедоступно вне сети.
                <br/>С вашего согласия: Мы можем раскрывать вашу персональную информацию для других целей с вашего
                согласия.
                <p className={"font-bold w-full"}>Сохранение ваших персональных данных</p>
                Компания будет хранить ваши персональные данные только настолько долго, насколько это необходимо для
                целей, установленных в настоящей Политике конфиденциальности. Мы будем хранить и использовать ваши
                персональные данные в той мере, в какой это необходимо для соблюдения наших юридических обязательств
                (например, если мы обязаны хранить ваши данные в соответствии с применимыми законами), разрешения споров
                и применения наших юридических соглашений и политик.
                <br/>Компания также будет сохранять данные использования для внутреннего анализа. Данные использования
                обычно хранятся в течение более короткого периода времени, за исключением случаев, когда эти данные
                используются для усиления безопасности или улучшения функциональности нашего Сервиса, или когда мы
                обязаны законом сохранять эти данные в течение более длительного периода времени.
                <p className={"font-bold w-full"}>Передача ваших персональных данных</p>
                Ваши сведения, включая персональные данные, обрабатываются в офисах Компании и в любых других местах,
                где находятся стороны, участвующие в обработке. Это означает, что эта информация может быть передана — и
                поддерживаться — на компьютерах, находящихся за пределами вашего государства, провинции, страны или
                другой государственной юрисдикции, где законы о защите данных могут отличаться от тех, которые действуют
                в вашей юрисдикции.
                <br/>Ваше согласие на настоящую Политику конфиденциальности, за которым следует ваше предоставление
                такой информации, представляет собой ваше согласие на эту передачу.
                <br/>Компания предпримет все разумные меры, необходимые для обеспечения безопасности ваших данных в
                соответствии с настоящей Политикой конфиденциальности, и никакой передачи ваших персональных данных не
                будет осуществлено в организацию или страну, если не будут предприняты адекватные меры контроля, включая
                безопасность ваших данных и другой личной информации.
                <p className={"font-bold w-full"}>Удаление ваших персональных данных</p>
                У вас есть право удалить или запросить, чтобы мы помогли в удалении персональных данных, которые мы
                собрали о вас.
                <br/>Наш Сервис может дать вам возможность удалить определенную информацию о вас изнутри Сервиса.
                <br/>Вы можете в любое время обновлять, изменять или удалять свою информацию, входя в свою учетную
                запись, если она у вас есть, и посещая раздел настроек учетной записи, который позволяет управлять вашей
                личной информацией. Вы также можете связаться с нами, чтобы запросить доступ к, исправить или удалить
                любую личную информацию, которую вы предоставили нам.
                <br/>Однако обратите внимание, что мы можем быть вынуждены сохранить определенную информацию, когда у
                нас есть юридическое обязательство или законное основание для этого.
                <br/>Раскрытие ваших персональных данных
                <p className={"font-bold w-full"}>Деловые операции</p>
                Если Компания участвует в слиянии, приобретении или продаже активов, ваши персональные данные могут быть
                переданы. Мы предоставим уведомление перед тем, как ваши персональные данные будут переданы и станут
                предметом другой Политики конфиденциальности.
                <p className={"font-bold w-full"}>Правоохранительные органы</p>
                В определенных обстоятельствах Компания может быть обязана раскрывать ваши персональные данные по
                требованию закона или в ответ на законные запросы от публичных органов (например, суда или
                государственного агентства).
                <p className={"font-bold w-full"}>Другие юридические требования</p>
                Компания может раскрывать ваши персональные данные в доброй вере, что такое действие необходимо:
                <br/>Соблюдать юридическое обязательство
                <br/>Защищать и защищать права или собственность Компании
                <br/>Предотвращать или расследовать возможные правонарушения в связи с Сервисом
                <br/>Защищать личную безопасность пользователей Сервиса или общественность
                <br/>Защищать от юридической ответственности
                <br/>Безопасность ваших персональных данных
                <br/>Безопасность ваших персональных данных важна для нас, но помните, что ни один способ передачи
                данных через Интернет или электронное хранение не является 100% безопасным. Хотя мы стремимся
                использовать коммерчески приемлемые средства для защиты ваших персональных данных, мы не можем
                гарантировать его абсолютную безопасность.
                <br/>
                <p className={"font-bold w-full"}>Конфиденциальность детей</p>
                Наш Сервис не предназначен для лиц младше 13 лет. Мы не собираем намеренно идентифицируемую личную
                информацию от лиц младше 13 лет. Если вы являетесь родителем или опекуном и знаете, что ваш ребенок
                предоставил нам персональные данные, свяжитесь с нами. Если мы узнаем, что мы собрали персональные
                данные от кого-либо младше 13 лет без проверки согласия родителя, мы предпримем шаги по удалению этой
                информации с наших серверов.
                <br/>Если нам нужно полагаться на согласие как на юридическую основу для обработки вашей информации, и
                ваша страна требует согласия родителя, мы можем потребовать согласия вашего родителя, прежде чем мы
                соберем и используем эту информацию.
                <p className={"font-bold w-full"}>Ссылки на другие веб-сайты</p>
                Наш Сервис может содержать ссылки на другие веб-сайты, которые не управляются нами. Если вы нажимаете на
                ссылку стороннего поставщика, вы будете перенаправлены на сайт этого стороннего поставщика. Мы
                настоятельно рекомендуем вам ознакомиться с Политикой конфиденциальности каждого сайта, который вы
                посещаете.
                <br/>Мы не контролируем и не несем ответственности за содержание, политику конфиденциальности или
                практики любых сторонних сайтов или служб.
                <p className={"font-bold w-full"}>Изменения в настоящей Политике конфиденциальности</p>
                Мы можем время от времени обновлять нашу Политику конфиденциальности. Мы уведомим вас о любых
                изменениях, разместив новую Политику конфиденциальности на этой странице.
                <br/>Мы сообщим вам по электронной почте и/или путем яркого уведомления на нашем Сервисе перед
                вступлением в силу изменений и обновим "Последнее обновление" вверху настоящей Политики
                конфиденциальности.
                <br/>Вам рекомендуется периодически просматривать нашу Политику конфиденциальности на предмет изменений.
                Изменения в нашу Политику конфиденциальности вступают в силу с момента их публикации на этой странице.
                <p className={"font-bold w-full"}>Связь с нами</p>
                Если у вас есть вопросы по поводу настоящей Политики конфиденциальности, вы можете связаться с нами:
                <br/>По электронной почте: info@sorpa.com
                <p className={"font-bold w-full"}>Реквизиты компании</p>
                Наименование: ТОО «Сорпа КЗ»
                <br/>Адрес: Казахстан, город Алматы, Ауэзовский район, Микрорайон 6, дом 58, кв. 23, почтовый индекс
                A10A6X8
                <br/>БИН: 240440034473
                <br/>БИК: BRKEKZKA
                <br/>КБе: 19
                <br/>Банк: AO «Bereke Bank»
                <br/>Номер счета: KZ37914032203KZ025AZ, (KZT)
                <br/>Директор: Тулеубаева Ш.У.
            </div>
        )
    }
}
