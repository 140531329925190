import '../App.css';
import {SorpaIcon} from "../assets/SorpaIcon";
import {Dropdown, DropdownTrigger, DropdownMenu, DropdownItem, Button} from "@nextui-org/react";
import {BurgerMenu} from "../assets/BurgerMenu";
import {useContext} from "react";
import {Anchor} from "../assets/Anchor";

const Header = () => {

    const lang = localStorage.getItem("lang");

    const l = {
        "ru": {
            "text1": {
                "label": "Добавка",
                "color": "white"
            },
            "text2": {
                "label": "Партнеры",
                "color": "white"
            },
            "text3": {
                "label": "О проекте",
                "color": "white"
            },
            "text4": {
                "label": "Контакты",
                "color": "white"
            },
            "text5": {
                "label": "Скачать",
                "color": "white"
            },
        },
        "kk": {
            "text1": {
                "label": "Қосымша",
                "color": "white"
            },
            "text2": {
                "label": "Серіктестеріміз",
                "color": "white"
            },
            "text3": {
                "label": "Жоба туралы",
                "color": "white"
            },
            "text4": {
                "label": "Байланысу",
                "color": "white"
            },
            "text5": {
                "label": "Жүктеу",
                "color": "white"
            },
        }
    }

    return (
        <div className={"w-full p-[12px] sticky top-0 z-50"}>
            <div className={"bg-[#242424] w-full max-w-[1200px] m-auto rounded-[24px] flex items-center justify-between p-[12px] md:px-[24px] py-[12px] border-[#DEE2ED] border-[1px]"}>
                <div className={"flex text-white items-center gap-[12px]"}>
                    <a href={"/"}><SorpaIcon/></a>
                    <a href={"/"}><p className={"text-[#BDFF00]"}>sorpa</p></a>
                    <a href={"https://business.sorpa.com"} className={"text-white hidden md:flex"}>sorpa pay</a>
                    <Dropdown
                        shadow={"sm"}
                        className={"bg-transparent"}
                        classNames={{
                            content: "bg-[#242424] mt-[10px]",
                        }}
                    >
                        <DropdownTrigger>
                            <button className={"flex items-center gap-[4px] text-[14px]"}>{lang === "ru" ? "Русский" : "Қазақша"} <Anchor/></button>
                        </DropdownTrigger>
                        <DropdownMenu aria-label="Static Actions"
                                      itemClasses={{
                                          base: [
                                              "data-[hover=true]:text-[white]",
                                              "data-[hover=true]:bg-[transparent]",
                                          ],
                                      }}
                        >
                            <DropdownItem key="new" className={"text-white"}>
                                <button onClick={() => {localStorage.setItem("lang", "ru"); window.location.reload()}}>Русский</button>
                            </DropdownItem>
                            <DropdownItem key="new" className={"text-white hover:bg-transparent"}>
                                <button onClick={() => {localStorage.setItem("lang", "kk"); window.location.reload()}}>Қазақша</button>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>


                </div>
                <div className={"hidden md:flex text-white items-center gap-[24px]"}>
                    <a className={"text-white text-[14px]"} href={'#dobavka'}>{l[lang]?.text1?.label}</a>
                    <a className={"text-white text-[14px]"} href={'#partners'}>{l[lang]?.text2?.label}</a>
                    <a className={"text-white text-[14px]"} href={'#about'}>{l[lang]?.text3?.label}</a>
                    <a className={"text-white text-[14px]"} href={'#contact'}>{l[lang]?.text4?.label}</a>
                    {
                        window.location.href.includes("kz") ? <a href={"/kz/download"}><Button className={"rounded-full bg-[#BDFF00] font-[600]"}>Скачать</Button></a> :
                            <a href={"/download"}><Button className={"rounded-full bg-[#BDFF00] font-[600]"}>{l[lang]?.text5?.label}</Button></a>
                    }

                </div>
                <div className={"flex md:hidden"}>
                    <Dropdown
                        shadow={"sm"}
                        className={"bg-transparent"}
                        classNames={{
                            content: "bg-[#242424] mt-[10px]",
                        }}
                    >
                        <DropdownTrigger>
                            <button><BurgerMenu/></button>
                        </DropdownTrigger>
                        <DropdownMenu aria-label="Static Actions"
                                      itemClasses={{
                                          base: [
                                              "data-[hover=true]:text-foreground",
                                              "data-[hover=true]:bg-[transparent]",
                                          ],
                                      }}
                        >
                            <DropdownItem key="new" className={"text-white"}>
                                <a href={"https://business.sorpa.com"} className={"text-white text-[18px]"}>sorpa pay</a>
                            </DropdownItem>
                            <DropdownItem key="new" className={"text-white"}>
                                <a className={"text-white text-[14px]"} href={'/#dobavka'}>{l[lang]?.text1?.label}</a>
                            </DropdownItem>
                            <DropdownItem key="new" className={"text-white"}>
                                <a className={"text-white text-[14px]"} href={'/#partners'}>{l[lang]?.text2?.label}</a>
                            </DropdownItem>
                            <DropdownItem key="new" className={"text-white"}>
                                <a className={"text-white text-[14px]"} href={'/#about'}>{l[lang]?.text3?.label}</a>
                            </DropdownItem>
                            <DropdownItem key="new" className={"text-white"}>
                                <a className={"text-white text-[14px]"} href={'/#contact'}>{l[lang]?.text4?.label}</a>
                            </DropdownItem>
                            <DropdownItem key="new" className={"text-white hover:bg-transparent"}>
                                <a className={"text-white text-[14px]"} href={"/download"}><Button className={"rounded-full bg-[#BDFF00] font-[600] w-full"}>{l[lang]?.text5?.label}</Button></a>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </div>
        </div>
    )
}
export default Header
