import {Button, Chip, Input} from "@nextui-org/react";
import {AppStoreIcon} from "../../assets/AppStoreIcon";
import {GooglePlayIcon} from "../../assets/GooglePlayIcon";
import BigSorpaCoin from "../../assets/BigSorpaCoin.webp";
import SmallSorpaCoin from "../../assets/SmallSorpaCoin.webp";
import SorpaScreen from "../../assets/SorpaScreen.webp";
import BigCard from "../../assets/BigCard.webp";
import Keks from "../../assets/keks.webp";
import Eggs from "../../assets/Eggs.webp";
import Fist from "../../assets/fist.webp";
import MobFist from "../../assets/mobilefist.webp";
import Hand from "../../assets/hand.webp";
import MobHand from "../../assets/MobHand.webp";
import Screen1 from "../../assets/Screen1.webp";
import Screen2 from "../../assets/Screen2.webp";
import Screen3 from "../../assets/Screen3.webp";
import Img1 from "../../assets/resto.webp";
import Img2 from "../../assets/face.webp";
import Img3 from "../../assets/clinik.webp";
import Img4 from "../../assets/sport.webp";
import Img5 from "../../assets/fun.webp";
import Img6 from "../../assets/clothes.webp";
import Img7 from "../../assets/clean.webp";
import Img8 from "../../assets/crm.webp";
import Img9 from "../../assets/flower.webp";
import Img10 from "../../assets/bal.webp";
import Img11 from "../../assets/travel.webp";
import {SorpaWhiteIcon} from "../../assets/SorpaWhiteIcon";
import {SorpaSmall} from "../../assets/SorpaSmall";
import {SmallestSorpa} from "../../assets/SmallestSorpa";
import {useState} from "react";
export const Main = () => {

    const [amount, setAmount] = useState("1 000 000");
    const [modifiedAmount, setModifiedAmount] = useState("1 200 000");
    const [addNumber, setAddNumber] = useState("200 000");
    const [error, setError] = useState(false);

    const handleChange = (e) => {
        const inputValue = e.target.value;


        // Удаляем все символы, кроме цифр
        const numericValue = inputValue.replace(/\D/g, '');

        // Преобразуем в число и форматируем в локальный формат ru-RU
        const formattedValue = new Intl.NumberFormat('ru-RU').format(numericValue);

        setAmount(formattedValue);

        let mValue = 0
        if (numericValue >= 1000 && numericValue < 10000){
            mValue = numericValue * 1.05
            setError(false);
        }else if(numericValue >= 10000 && numericValue < 50000){
            mValue = numericValue * 1.1
            setError(false);
        }else if(numericValue >= 50000 && numericValue < 100000){
            mValue = numericValue * 1.15
            setError(false);
        }else if(numericValue >= 100000){
            mValue = numericValue * 1.2
            setError(false);
        }
        if (numericValue >= 1000){
            const addNumberValue = mValue - numericValue
            const formattedM = new Intl.NumberFormat('ru-RU').format(mValue);
            const formattedAM = new Intl.NumberFormat('ru-RU').format(addNumberValue);
            setModifiedAmount(formattedM);
            setAddNumber(formattedAM);
        }else{
            setError(true);
            setModifiedAmount("0");
            setAddNumber("0");
        }
    };

    const lang = localStorage.getItem("lang");

    const l = {
        "ru": {
            "text1": {
                "label": "Добавка",
                "color": "#BDFF00"
            },
            "text2": {
                "label": "к вашему балансу",
                "color": "white"
            },
            "text3": {
                "label": "Оплачивайте балансом с добавкой во всех ваших любимых заведениях Добавляем до 20% к вашему бюджету",
                "color": "white"
            },
            "text4": {
                "label": "Как увеличить свой баланс?",
                "color": "black"
            },
            "text5": {
                "label": "Где можно потратить добавку?",
                "color": "black"
            },
            "text6": {
                "label": "Доступно к скачиванию",
                "color": "white"
            },
            "text7": {
                "label": "Что такое добавка?",
                "color": "black"
            },
            "text8": {
                "label": "Добавка (cash-up) моментальное получение бонусных баллов с увеличением за пополнение счета в мобильном приложении",
                "color": "black"
            },
            "text9": {
                "label": "Получить больше",
                "color": "black"
            },
            "text10": {
                "label": "Позволяет до 20% увеличить свой баланс",
                "color": "black"
            },
            "text11": {
                "label": "Выгода сразу",
                "color": "black"
            },
            "text12": {
                "label": "Добавку можно потратить сразу",
                "color": "black"
            },
            "text13": {
                "label": "Сначала добавка",
                "color": "black"
            },
            "text14": {
                "label": "С вашего счета списывается добавка, затем основной баланс",
                "color": "black"
            },
            "text15": {
                "label": "Процесс пополнения баланса с добавкой",
                "color": "black"
            },
            "text16": {
                "label": "Укажите сумму пополнения",
                "color": "#888888"
            },
            "text17": {
                "label": "Итого на вашем счету:",
                "color": "white"
            },
            "text18": {
                "label": "добавки",
                "color": "#BDFF00"
            },
            "text19": {
                "label": "От",
                "color": "white"
            },
            "text20": {
                "label": "Как пользоваться приложением?",
                "color": "black"
            },
            "text21": {
                "label": "Скачайте приложение",
                "color": "black"
            },
            "text22": {
                "label": "Более 70 наших партнеров уже ждут вас в нашем приложении",
                "color": "black"
            },
            "text23": {
                "label": "Пополните баланс",
                "color": "black"
            },
            "text24": {
                "label": "Получите моментально добавку до 20% и увеличьте свой баланс",
                "color": "black"
            },
            "text25": {
                "label": "Оплатите по QR-коду",
                "color": "black"
            },
            "text26": {
                "label": "Сначала тратится добавка",
                "color": "black"
            },
            "text27": {
                "label": "Где можно оплатить с добавкой?",
                "color": "black"
            },
            "text28": {
                "label": "Кафе и Рестораны",
                "color": "white"
            },
            "text29": {
                "label": "Красота",
                "color": "white"
            },
            "text30": {
                "label": "Здоровье",
                "color": "white"
            },
            "text31": {
                "label": "Спорт",
                "color": "white"
            },
            "text32": {
                "label": "Развлечения",
                "color": "white"
            },
            "text33": {
                "label": "Одежда",
                "color": "white"
            },
            "text34": {
                "label": "Услуги",
                "color": "white"
            },
            "text35": {
                "label": "Финансы и учет",
                "color": "white"
            },
            "text36": {
                "label": "Цветы",
                "color": "white"
            },
            "text37": {
                "label": "Банкетные залы",
                "color": "white"
            },
            "text38": {
                "label": "Туризм",
                "color": "white"
            },
            "text39": {
                "label": "Скачивай приложение и увеличивай баланс вместе с нами",
                "color": "white"
            },
        },
        "kk": {
            "text1": {
                "label": "Сіздің балансыңызға",
                "color": "white"
            },
            "text2": {
                "label": "қосымша",
                "color": "#BDFF00"
            },
            "text3": {
                "label": "Барлық сүйікті орындарыңызда балансқа қосымша арқылы төлеңіз Бюджетіңізді 20%-ға дейін арттырамыз",
                "color": "white"
            },
            "text4": {
                "label": "Балансымды қалай арттыруға болады?",
                "color": "black"
            },
            "text5": {
                "label": "Қосымшаны қайда жұмсауға болады?",
                "color": "black"
            },
            "text6": {
                "label": "Жүктеп алуға қолжетімді",
                "color": "white"
            },
            "text7": {
                "label": "Қосымша дегеніміз не?",
                "color": "white"
            },
            "text8": {
                "label": "Қосымша (cash-up) - Бонус ұпайларын мобильді қосымшада шотты толықтыру арқылы бірден алу",
                "color": "black"
            },
            "text9": {
                "label": "Көбірек алу",
                "color": "black"
            },
            "text10": {
                "label": "Балансыңды 20%-ға дейін арттырады",
                "color": "black"
            },
            "text11": {
                "label": "Жедел пайда",
                "color": "black"
            },
            "text12": {
                "label": "Қосымшаны бірден жұмсауға болады",
                "color": "black"
            },
            "text13": {
                "label": "Алдымен қосымша",
                "color": "black"
            },
            "text14": {
                "label": "Алдымен сіздің шотыңыздан қосымша, содан кейін негізгі баланс алынады",
                "color": "black"
            },
            "text15": {
                "label": "Баланс және қосымшаны толтыру процессі",
                "color": "black"
            },
            "text16": {
                "label": "Толықтыру сомасын көрсетіңіз",
                "color": "#888888"
            },
            "text17": {
                "label": "Шотыңыздағы жалпы сома",
                "color": "white"
            },
            "text18": {
                "label": "қосымша",
                "color": "#BDFF00"
            },
            "text19": {
                "label": "-нан",
                "color": "white"
            },
            "text20": {
                "label": "Мобильді қосымшамен қалай қолданады?",
                "color": "black"
            },
            "text21": {
                "label": "Мобильді қосымшаны жүктеңіз",
                "color": "black"
            },
            "text22": {
                "label": "70-тен астам серіктестеріміз сізді мобильді қосымшамызда күтуде",
                "color": "black"
            },
            "text23": {
                "label": "Балансты толтырыңыз",
                "color": "black"
            },
            "text24": {
                "label": "20%-ға дейін қосымшаңызды бірден алып, балансыңызды арттырыңыз",
                "color": "black"
            },
            "text25": {
                "label": "QR-кодпен төлеңіз",
                "color": "black"
            },
            "text26": {
                "label": "Алдымен қосымша жұмсалынады",
                "color": "black"
            },
            "text27": {
                "label": "Қосымша арқылы қайда төлеуге болады?",
                "color": "black"
            },
            "text28": {
                "label": "Кафе мен Мейрамханалар",
                "color": "white"
            },
            "text29": {
                "label": "Сұлулық",
                "color": "white"
            },
            "text30": {
                "label": "Денсаулық",
                "color": "white"
            },
            "text31": {
                "label": "Спорт",
                "color": "white"
            },
            "text32": {
                "label": "Ойын-сауық",
                "color": "white"
            },
            "text33": {
                "label": "Киім",
                "color": "white"
            },
            "text34": {
                "label": "Қызметтер",
                "color": "white"
            },
            "text35": {
                "label": "Қаржы және есеп",
                "color": "white"
            },
            "text36": {
                "label": "Гүлдер",
                "color": "white"
            },
            "text37": {
                "label": "Банкет залдары",
                "color": "white"
            },
            "text38": {
                "label": "Туризм",
                "color": "white"
            },
            "text39": {
                "label": "Қосымшаны жүктеп ал да, балансынды бізбен бірге арттыр",
                "color": "white"
            },
        }
    }

    return(
        <div>
            <section className={"container"} id={""}>
                <div className={"w-full relative z-40"}>
                    <div className={"hero-back w-full mb-[40px] rounded-[24px] p-[24px] text-white flex flex-col min-h-[540px] justify-between relative z-50 md:h-[690px] overflow-hidden"}>
                        <img src={BigSorpaCoin} className={"absolute z-30 top-[-64px] md:top-[-14px] right-[-162px] md:right-[200px] w-[360px] md:w-[451px] object-cover"}/>
                        <img src={SmallSorpaCoin} className={"absolute z-30 top-[483px] left-[-37px] md:left-[270px] w-[160px] md:w-[208px]"}/>
                        <img src={SorpaScreen} className={"absolute z-30 top-[180px] md:right-[20px] lg:right-[80px] w-[337px] hidden md:flex"}/>
                        <div className={"flex flex-col gap-[16px] mt-[60px] relative z-50"}>
                            <h1 className={`text-[40px] text-[${l[lang]?.text2?.color}] font-[600] leading-[54px]`}><span className={`text-[${l[lang]?.text1?.color}]`}>{l[lang]?.text1?.label}</span> {lang === "kk" && <br/>} {l[lang]?.text2?.label}</h1>
                            <p className={`max-w-[500px] text-[${l[lang]?.text3?.color}]`}>{l[lang]?.text3?.label}</p>
                            <div className={"flex w-full max-w-[500px] justify-between gap-[16px] flex-col md:flex-row"}>
                                <a href={"/#about"}><Button fullWidth={true} className={`text-[${l[lang]?.text4?.color}] bg-[#BDFF00] font-[600]`}>{l[lang]?.text4?.label}</Button></a>
                                <a href={"/#partners"}><Button fullWidth={true} className={`text-[${l[lang]?.text5?.color}] bg-[#BDFF00] font-[600] w-full`}>{l[lang]?.text5?.label}</Button></a>
                            </div>
                        </div>
                        <div className={"mt-[80px] lg:mb-[40px] flex flex-col gap-[16px]"}>
                            <p className={`w-full text-[${l[lang]?.text6?.color}] text-right md:text-left`}>{l[lang]?.text6?.label}</p>
                            <div className={"flex justify-end md:justify-start gap-[16px] relative z-30"}>
                                <a href={"https://apps.apple.com/kz/app/sorpa/id6480465302"}><AppStoreIcon/></a>
                                <a href={"https://play.google.com/store/apps/details?id=com.bekinston.Sorpa&hl=en"}><GooglePlayIcon/></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id={'dobavka'} className={"w-full bg-white"}>
                <div className={"container py-[40px] md:py-[100px] gap-[24px]"}>
                    <div className={"flex items-center justify-start w-full gap-[12px]"}>
                        <h2 className={"text-[28px]"}>{l[lang]?.text7?.label}</h2>
                        <SorpaWhiteIcon/>
                    </div>
                    <p className={"w-full"}>{l[lang]?.text8?.label}</p>
                    <div className={"w-full flex gap-[16px]"}>
                        <img src={BigCard} className={"max-w-[384px] w-full hidden xl:flex"}/>
                        <div className={"flex flex-col gap-[8px] relative"}>
                            <div className={"flex flex-col md:flex-row gap-[8px] relative"}>
                                <div className={"bg-[#DEE2ED] rounded-[24px] p-[24px] min-w-[260px] w-full relative overflow-hidden h-[200px] md:h-[266px] gap-[12px] flex flex-col"}>
                                    <h3 className={"text-[24px] font-[500 relative z-40]"}>{l[lang]?.text9?.label}</h3>
                                    <p className={"text-[14px] relative z-40"}>{l[lang]?.text10?.label}</p>
                                    <img src={Keks} className={"absolute bottom-0 right-0 w-[120px] md:w-[180px] z-30"}/>
                                </div>
                                <div className={"bg-[#DEE2ED] rounded-[24px] p-[24px] min-w-[260px] w-full relative overflow-hidden h-[200px] md:h-[266px] gap-[12px] flex flex-col"}>
                                    <h3 className={"text-[24px] font-[500 relative z-40]"}>{l[lang]?.text11?.label}</h3>
                                    <p className={"text-[14px] relative z-40"}>{l[lang]?.text12?.label}</p>
                                    <img src={Eggs} className={"absolute bottom-[-10px] md:bottom-0 left-[12px] w-[180px] z-30"}/>
                                    <img src={Fist} className={"absolute bottom-0 right-0 top-[130px] z-30 w-[94px] hidden md:flex"}/>
                                    <img src={MobFist} className={"absolute right-[20px] bottom-0 z-30 w-[180px] flex md:hidden"}/>
                                </div>
                                <div className={"bg-[#DEE2ED] rounded-[24px] p-[24px] min-w-[260px] w-full relative overflow-hidden h-[200px] md:h-[266px] gap-[12px] flex flex-col"}>
                                    <h3 className={"text-[24px] font-[500 relative z-40]"}>{l[lang]?.text13?.label}</h3>
                                    <p className={"text-[14px] relative z-40"}>{l[lang]?.text14?.label}</p>
                                    <img src={Hand} className={"absolute bottom-0 left-0 w-[110px] z-30 hidden md:flex"}/>
                                    <img src={MobHand} className={"absolute top-0 right-0 w-[110px] z-30 flex md:hidden"}/>
                                </div>
                            </div>
                            <div className={"bg-[#DEE2ED] rounded-[24px] p-[24px] w-full relative overflow-hidden gap-[12px] flex flex-col"}>
                                <h3 className={"text-[24px] font-[500 relative z-40]"}>{l[lang]?.text15?.label}</h3>
                                <div className={"flex flex-col md:flex-row gap-[8px]"}>
                                    <div className={"flex w-full flex-col bg-white gap-[4px] rounded-[8px] p-[12px]"}>
                                        <h4 className={"text-[#888888]"}>{l[lang]?.text16?.label}</h4>
                                        <input
                                            className={"focus-visible:ring-offset-0 focus-visible:ring-0 text-[24px] caret-[#4469FF]"}
                                            type="text"
                                            value={amount}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className={"flex w-full flex-col bg-[#222222] gap-[4px] rounded-[8px] p-[12px]"}>
                                        <h4 className={"text-[white]"}>{l[lang]?.text17?.label}</h4>
                                        <p className={"text-[24px] text-[white] flex items-center gap-[8px] font-[600]"}>{modifiedAmount}<span className={"text-[#4469FF]"}><SorpaSmall/></span></p>
                                        <p className={"text-[14px] text-[white] flex items-center gap-[4px] font-[600]"}>{amount}<span className={"text-[#4469FF]"}><SmallestSorpa/></span> <span className={"text-[#BDFF00]"}>+{addNumber} {l[lang]?.text18?.label}</span></p>
                                    </div>
                                </div>
                                <div className={"w-full flex flex-col md:flex-row gap-[8px]"}>
                                    <div className={"flex w-full gap-[8px]"}>
                                        <div className={"flex w-full flex-col bg-[#222222] gap-[4px] rounded-[8px] justify-center items-center p-[8px]"}>
                                            <h4 className={"text-[white] text-center"}>{lang === "ru" && "От"} 1 000{lang === "kk" && "-нан"}</h4>
                                            <p className={"text-[#BDFF00] font-[800] text-center w-full"}>+5%</p>
                                        </div>
                                        <div className={"flex w-full flex-col bg-[#222222] gap-[4px] rounded-[8px] justify-center items-center p-[8px]"}>
                                            <h4 className={"text-[white] text-center"}>{lang === "ru" && "От"} 10 000{lang === "kk" && "-нан"}</h4>
                                            <p className={"text-[#BDFF00] font-[800] text-center w-full"}>+10%</p>
                                        </div>
                                    </div>
                                    <div className={"flex w-full gap-[8px]"}>
                                        <div className={"flex w-full flex-col bg-[#222222] gap-[4px] rounded-[8px] justify-center items-center p-[8px]"}>
                                            <h4 className={"text-[white] text-center"}>{lang === "ru" && "От"} 50 000{lang === "kk" && "-нан"}</h4>
                                            <p className={"text-[#BDFF00] font-[800] text-center w-full"}>+15%</p>
                                        </div>
                                        <div className={"flex w-full flex-col bg-[#222222] gap-[4px] rounded-[8px] justify-center items-center p-[8px]"}>
                                            <h4 className={"text-[white] text-center"}>{lang === "ru" && "От"} 100 000{lang === "kk" && "-нан"}</h4>
                                            <p className={"text-[#BDFF00] font-[800] text-center w-full"}>+20%</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className={"flex flex-col items-center justify-start w-full gap-[12px]"}>
                        <p>{l[lang]?.text6?.label}</p>
                        <div className={"flex justify-end md:justify-start gap-[16px] relative z-30"}>
                            <a href={"https://apps.apple.com/kz/app/sorpa/id6480465302"}><AppStoreIcon/></a>
                            <a href={"https://play.google.com/store/apps/details?id=com.bekinston.Sorpa&hl=en"}><GooglePlayIcon/></a>
                        </div>
                    </div>
                </div>
            </section>
            <section id={'about'} className={"container"}>
                <div className={"container py-[40px] md:py-[100px] gap-[24px] md:gap-[36px]"}>
                    <div className={"flex items-center justify-start w-full gap-[12px]"}>
                        <h2 className={"text-[28px]"}>{l[lang]?.text20?.label}</h2>
                    </div>
                    <div className={"flex flex-col md:flex-row items-center justify-start w-full gap-[24px]"}>
                        <div className={"bg-[#FFFFFF] rounded-[22px] p-[24px] w-full overflow-hidden gap-[12px] flex flex-col relative h-[570px]"}>
                            <h3 className={"text-[24px] font-[600] text-center"}>{l[lang]?.text21?.label}</h3>
                            <p className={"text-center"}>{l[lang]?.text22?.label}</p>
                            <img src={Screen1} className={"absolute bottom-0 right-0"}/>
                        </div>
                        <div className={"bg-[#FFFFFF] rounded-[22px] p-[24px] w-full overflow-hidden gap-[12px] flex flex-col relative h-[570px]"}>
                            <h3 className={"text-[24px] font-[600] text-center"}>{l[lang]?.text23?.label}</h3>
                            <p className={"text-center"}>{l[lang]?.text24?.label}</p>
                            <img src={Screen2} className={"absolute bottom-0 right-0"}/>
                        </div>
                        <div className={"bg-[#FFFFFF] rounded-[22px] p-[24px] w-full overflow-hidden gap-[12px] flex flex-col relative h-[570px]"}>
                            <h3 className={"text-[24px] font-[600] text-center"}>{l[lang]?.text25?.label}</h3>
                            <p className={"text-center"}>{l[lang]?.text26?.label}</p>
                            <img src={Screen3} className={"absolute bottom-0 right-0"}/>
                        </div>
                    </div>
                </div>
            </section>
            <section id={'partners'} className={"w-full bg-white"}>
                <div className={"container py-[40px] md:py-[100px] gap-[24px]"}>
                    <div className={"flex items-center justify-start w-full gap-[12px]"}>
                        <h2 className={"text-[28px]"}>{l[lang]?.text27?.label}</h2>
                    </div>
                    <div className={"flex flex-col w-full gap-[16px] md:gap-[4px]"}>
                        <div className={"w-full flex flex-col md:flex-row gap-[12px] md:gap-[4px]"}>
                            <div className={"h-[200px] md:h-[300px] w-full rounded-[24px] relative"}>
                                <h4 className={"relative z-40 text-white p-[24px] text-[28px]"}>{l[lang]?.text28?.label}</h4>
                                <img src={Img1} className={"w-full h-full absolute z-30 top-0 object-cover rounded-[24px]"}/>
                            </div>
                            <div className={"h-[200px] md:h-[300px] w-full md:max-w-[324px] rounded-[24px] relative"}>
                                <h4 className={"relative z-40 text-white p-[24px] text-[28px]"}>{l[lang]?.text29?.label}</h4>
                                <img src={Img2} className={"w-full h-full absolute z-30 top-0 object-cover rounded-[24px]"}/>
                            </div>
                            <div className={"h-[200px] md:h-[300px] w-full rounded-[24px] relative"}>
                                <h4 className={"relative z-40 text-white p-[24px] text-[28px]"}>{l[lang]?.text30?.label}</h4>
                                <img src={Img3} className={"w-full h-full absolute z-30 top-0 object-cover rounded-[24px]"}/>
                            </div>
                        </div>
                        <div className={"w-full flex flex-col md:flex-row gap-[12px] md:gap-[4px]"}>
                            <div className={"h-[200px] md:h-[300px] w-full rounded-[24px] relative"}>
                                <h4 className={"relative z-40 text-white p-[24px] text-[28px]"}>{l[lang]?.text31?.label}</h4>
                                <img src={Img4} className={"w-full h-full absolute z-30 top-0 object-cover rounded-[24px]"}/>
                            </div>
                            <div className={"h-[200px] md:h-[300px] w-full rounded-[24px] relative"}>
                                <h4 className={"relative z-40 text-white p-[24px] text-[28px]"}>{l[lang]?.text32?.label}</h4>
                                <img src={Img5} className={"w-full h-full absolute z-30 top-0 object-cover rounded-[24px]"}/>
                            </div>
                            <div className={"h-[200px] md:h-[300px] w-full rounded-[24px] relative"}>
                                <h4 className={"relative z-40 text-white p-[24px] text-[28px]"}>{l[lang]?.text33?.label}</h4>
                                <img src={Img6} className={"w-full h-full absolute z-30 top-0 object-cover rounded-[24px]"}/>
                            </div>
                        </div>
                        <div className={"w-full flex flex-col md:flex-row gap-[12px] md:gap-[4px]"}>
                            <div className={"h-[200px] md:h-[300px] w-full rounded-[24px] relative"}>
                                <h4 className={"relative z-40 text-white p-[24px] text-[28px]"}>{l[lang]?.text34?.label}</h4>
                                <img src={Img7} className={"w-full h-full absolute z-30 top-0 object-cover rounded-[24px]"}/>
                            </div>
                            <div className={"h-[200px] md:h-[300px] w-full md:max-w-[324px] rounded-[24px] relative"}>
                                <h4 className={"relative z-40 text-white p-[24px] text-[28px]"}>{l[lang]?.text35?.label}</h4>
                                <img src={Img8} className={"w-full h-full absolute z-30 top-0 object-cover rounded-[24px]"}/>
                            </div>
                            <div className={"h-[200px] md:h-[300px] w-full rounded-[24px] relative"}>
                                <h4 className={"relative z-40 text-white p-[24px] text-[28px]"}>{l[lang]?.text36?.label}</h4>
                                <img src={Img9} className={"w-full h-full absolute z-30 top-0 object-cover rounded-[24px]"}/>
                            </div>
                        </div>
                        <div className={"w-full flex flex-col md:flex-row gap-[12px] md:gap-[4px]"}>
                            <div className={"h-[200px] md:h-[300px] w-full rounded-[24px] relative"}>
                                <h4 className={"relative z-40 text-white p-[24px] text-[28px]"}>{l[lang]?.text37?.label}</h4>
                                <img src={Img10} className={"w-full h-full absolute z-30 top-0 object-cover rounded-[24px]"}/>
                            </div>
                            <div className={"h-[200px] md:h-[300px] w-full rounded-[24px] relative"}>
                                <h4 className={"relative z-40 text-white p-[24px] text-[28px]"}>{l[lang]?.text38?.label}</h4>
                                <img src={Img11} className={"w-full h-full absolute z-30 top-0 object-cover rounded-[24px]"}/>
                            </div>
                        </div>
                    </div>
                    <div className={"flex flex-col gap-[16px]"}>
                        <p className={"w-full text-center text-[28px]"}>{l[lang]?.text39?.label}</p>
                        <div className={"flex justify-center gap-[16px] relative z-30"}>
                            <a href={"https://apps.apple.com/kz/app/sorpa/id6480465302"}><AppStoreIcon/></a>
                            <a href={"https://play.google.com/store/apps/details?id=com.bekinston.Sorpa&hl=en"}><GooglePlayIcon/></a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
